import React, { useEffect, useState } from "react";

import Circle from "components/Tools/Circle";
import proxy from "api/proxy";

const statusCtx = {
  open: { status: "open", label: "Open", color: "lime" },
  closed: { status: "closed", label: "Closed", color: "rose" },
};

function CellStatus({ data }) {
  const [dataSig, setDataSig] = useState(statusCtx.open);

  async function onClickStatus() {
    if (data.id) {
      const _status = dataSig.status === "open" ? "closed" : "open";
      setDataSig(statusCtx[_status]);
      const args = {
        model: "hotel.calendar.line",
        storeRec: {
          id: data.id,
          status: _status,
        },
      };
      await proxy.saveQuery(args);
    }
  }

  useEffect(() => {
    if (data.status === "closed") {
      setDataSig(statusCtx[data.status]);
    }
  }, [data]);

  return (
    <div className="flex active:scale-95 cusor-pointer" onClick={onClickStatus}>
      <Circle color={dataSig.color} size="w-3 h-3" />
      <span className="text-xs text-neutral-500">{dataSig.label}</span>
    </div>
  );
}

export default CellStatus;
