import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import proxy from "api/proxy";
import StdButton from "components/Buttons/StdButton";
import useMainStore from "store/mainStore";
import { SearchRecord } from "components/Tools/SearchRecord";

function ProductDiscount({
  onClose,
  handleCurrent = false,
  grid = "grid-cols-2 md:grid-cols-4",
}) {
  const { record, reload } = useMainStore();
  const [selected, setSelected] = useState([]);
  const [checked, setChecked] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [searchData, setSearchData] = useState([]);

  const discountIds = selected.map((item) => item.id);
  const discount = selected.reduce((sum, item) => sum + item.discount, 0);

  const shopId = record?.shop?.id;

  const queryProductDiscount = useQuery(
    ["productDiscount", shopId],
    async () => {
      let discounts = [];
      if (shopId) {
        const { data: shops, error } = await proxy.search(
          "sale.shop",
          [
            ["id", "=", shopId],
            ["discounts.active", "=", true],
          ],
          ["discounts.name", "discounts.type_discount", "discounts.discount"],
        );
        if (shops) {
          discounts = shops[0]["discounts."];
        }
      }
      return discounts;
    },
  );

  const handleSelect = (discount) => {
    let selected_ = [...selected];
    if (selected_.includes(discount)) {
      const idx = selected_.findIndex((t) => discount === t);
      selected_.splice(idx, 1);
    } else {
      selected_.push(discount);
    }
    setSelected(selected_);
  };

  const onAcept = async () => {
    const lines = record.lines;

    if (checked) {
      const args = {
        line_ids: Array.from(lines.keys()),
        value: 0,
        type: "percentage",
      };
      await proxy.methodCall({
        model: "sale.line",
        method: "faster_set_discount",
        args: [args],
      });
    } else {
      if (!handleCurrent) {
        for (const item of lines.values()) {
          const new_unit_price = Number(
            item.unit_price - (item.unit_price * discount) / 100,
          )
            .toFixed(2)
            .toString();
          await proxy.saveQuery({
            model: "sale.line",
            storeRec: {
              unit_price: new_unit_price,
              id: item.id,
              quantity: item.quantity,
            },
          });
        }
      } else {
        handleCurrent(selected[0]);
        return false;
      }
    }
    reload();
    onClose(true, false);
  };

  const resetSearchData = () => {
    setOriginalData(originalData);
  };

  useEffect(() => {
    if (queryProductDiscount.data && queryProductDiscount.data.length > 0) {
      setSearchData(queryProductDiscount.data);
    }
  }, [queryProductDiscount.data]);

  return (
    <div className="relative">
      <div className="sticky top-0 z-10 bg-gray-50 pb-0.5">
        <SearchRecord
          data={queryProductDiscount.data}
          setSearchData={setSearchData}
          resetSearchData={resetSearchData}
          title="Buscar tipo de descuento"
        />
      </div>
      <div
        className={`grid gap-4 ${grid} px-4 sm:px-6 lg:px-8 py-2 md:col-span-2`}
        title={discount.name}
      >
        {searchData.map((discount, index) => {
          const active = discountIds.includes(discount.id);
          return (
            <div
              className={`group rounded-md cursor-pointer text-center hover:bg-yellow-500 px-2 py-2 relative overflow-hidden min-h-32 ${
                active ? "bg-yellow-500" : "bg-blue-presik"
              }`}
              key={discount.id}
              onClick={() => {
                handleSelect(discount);
                // onClick={() => {
                //   handleCurrent
                //     ? console.log("paso por aca")
                //     : handleSelect(discount);
              }}
              name={index}
            >
              <div className="pointer-events-none flex-wrap px-3 border h-full border-white py-3 border-dashed items-stretch text-white">
                <h3 className="font-semibold text-[17px] ">
                  <span className="line-clamp-2 h-2/3">{discount.name}</span>
                </h3>
                <span className="text-gray-500 text-[30px] h-1/3">
                  {discount.discount}{" "}
                  {discount.type_discount === "percentage" ? "%" : ""}
                </span>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex mt-5 justify-between items-center space-x-3 sticky -bottom-2 py-2 px-4 bg-gray-50">
        <span>TOTAL DESCUENTO: {discount}%</span>
        <div>
          <span>Resetear Descuentos </span>
          <input type="checkbox" onClick={() => setChecked(!checked)} />
        </div>
        <StdButton
          color={"bluePresik"}
          onClick={onAcept}
          name={"done"}
          content={"modal_form.button_accept"}
        />
      </div>
    </div>
  );
}

export default ProductDiscount;
