import React, { useState } from "react";
import { FormattedMessage as FM } from "react-intl";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Attachment from "components/Attachment/Attachment";
import IconButton from "components/Buttons/IconButton";
import MenuReport from "components/MeForm/MenuReport";
import {
  saveIcon,
  attachmentIcon,
  deleteIcon,
} from "components/MeForm/iconForm";
import mainStore from "store/mainStore";
import { classNames } from "tools/ui";

const colspan = "md:col-span-2 lg:col-span-2 xl:col-span-2";
const styleHead =
  "flex pt-2 pb-3 w-full px-3 lg:px-4 lg:pt-3 md:col-span-2 lg:col-span-2 xl:col-span-2";

function HeadForm({
  title,
  onClose,
  onSave,
  ctxView,
  kind,
  onChangeView,
  record,
}) {
  const { store } = mainStore();
  const [viewAttach, setViewAttach] = useState(false);
  const { model, form_action, access } = ctxView;
  let btnAttachment;
  let btnDelete;
  let btnSave;
  let btnReports;
  if (form_action) {
    btnAttachment = form_action.includes("attachment");
    let accessDelete = true;
    if (access && access.delete) {
      // must return true or false depends of the record evaluation
      accessDelete = access.delete(record);
    }
    if (accessDelete) {
      btnDelete = form_action.includes("delete");
    }
    // if (record.id > 0) {
    //   btnSave = form_action.includes("edit");
    // }
    /***the save button is necessary when the user is filling out the form
     *  and wants to save the form so as not to lose all the information,
     * e.g., shipment internal  */
    let accessEdit = true;
    if (access && access.edit) {
      // must return true or false depends of the record evaluation
      accessEdit = access.edit(record);
    }
    if (accessEdit) {
      btnSave = form_action.includes("edit");
    }
    btnReports = ctxView.reports;
  }
  // FIXME: Add report if exists change to true trigger generate
  // const report = false;

  let bgColor = "";
  if (kind === "modal") {
    bgColor = "bg-slate-200";
  }

  const titleModel = model;
  let titleValue = "";
  if (title && title.field) {
    const nameField_ = `${title.field}.`;
    if (record[nameField_]) {
      titleValue = record[nameField_];
    } else {
      titleValue = record[title.field];
    }
    if (titleValue instanceof Object) {
      titleValue = titleValue.rec_name;
    }
  }

  function onAttachment() {
    setViewAttach(true);
  }

  function onDelete() {
    onSave("delete", [record]);
  }

  const toolbar = btnSave || btnAttachment || btnReports || btnDelete;

  return (
    <div id="head-form" className={classNames(styleHead, bgColor)}>
      <h1 className={classNames("text-zinc-500 text-4xl", colspan)}>
        {titleModel && (
          <span className="font-light">
            {titleModel && <FM id={titleModel} />}
          </span>
        )}

        {titleValue && (
          <>
            <span> | </span>
            <span className="text-gray-700 font-bold">{titleValue}</span>
          </>
        )}
      </h1>
      <div className="flex ml-auto my-auto gap-2 space-x-6" id="actions_modal">
        {toolbar && (
          <div className="flex py-1 ml-auto my-auto rounded-md px-6 space-x-6">
            {saveIcon(btnSave, onSave)}
            {attachmentIcon(btnAttachment, onAttachment)}
            {deleteIcon(btnDelete, onDelete)}
            {btnReports && (
              <MenuReport ctxView={ctxView} activeRecord={record} />
            )}
          </div>
        )}
        {kind === "modal" ? (
          <XMarkIcon
            onClick={onClose}
            className="h-8 w-8 text-zinc-600 stroke-2 p-1 cursor-pointer hover:text-rose-700 my-auto"
            aria-hidden="true"
          />
        ) : (
          <IconButton
            onClick={(e) => onChangeView(e, "return", store)}
            color="lime"
            name="fi fi-rr-arrow-small-left"
            tooltip="board.button_back"
          />
        )}
      </div>
      {viewAttach && (
        <Attachment
          activeRecord={record}
          onClose={() => {
            setViewAttach(false);
          }}
          ctxView={ctxView}
          open={viewAttach}
        />
      )}
    </div>
  );
}

export default HeadForm;
