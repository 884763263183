import DeliveryPanel from "apps/DeliveryPanel/DeliveryPanel";
import SaleOrder from "apps/SaleOrder/SaleOrder";
import PurchaseOrder from "apps/PurchaseOrder/PurchaseOrder";
import StockRequest from "apps/StockRequest/StockRequest";
import PurchaseApproval from "../apps/PurchaseApproval/PurchaseApproval";
import Schedule from "apps/Surveillance/Schedule";
import StaffControlAccess from "apps/Staff/StaffControlAccess";
import Inventory from "apps/Inventory/Inventory";
import SaleCallCenter from "apps/SaleCallCenter/SaleCallCenter";
import HotelCalendar from "apps/HotelCalendar/RateCalendar";
import HotelRack from "apps/HotelRack/Rack";
import TakeOrder from "apps/TakingOrder/TakeOrder";
import OrderViewer from "apps/OrderViewer/OrderViewer";
import PurchaseRequisition from "apps/PurchaseRequisition/PurchaseRequisition";
import Housekeeping from "apps/Housekeeping/Housekeeping";
import OrderNotification from "apps/OrderNotification/OrderNotification";
import CRMSales from "apps/CRMSales/Opportunity";
import RestOrder from "apps/RestOrder/RestOrder";
import WebCheckIn from "ext-apps/WebCheckIn/WebCheckIn";
import CrmFiduprevisora from "ext-apps/CrmFiduprevisora/CrmFiduprevisora";
import ConsultPQR from "ext-apps/ConsultPQR/ConsultPQR";
import SelfServiceSale from "ext-apps/SelfServiceSale/SelfServiceSale";
import SaleTurn from "ext-apps/SaleTurn/SaleTurn";
import Booking from "apps/Booking/ScreenBooking";
import Activity from "apps/Activity/Activity";
import PortalCustomer from "apps/PortalCustomer/PortalCustomer";
import ScreenRental from "apps/Rental/ScreenRental";
import RentalBooking from "apps/RentalBooking/RentalBooking";
import WebBookingRental from "ext-apps/BookingRental/WebBookingRental";
import SaleAudit from "apps/SaleAudit/SaleAudit";
import Catalog from "apps/Catalog/Catalog";
import ScreenMaintenance from "apps/Maintenance/ScreenMaintenance";
import WebBookingHotel from "ext-apps/WebBookingHotel/WebBooking";
import ScreenMelhous from "ext-apps/WebMelhous/ScreenMelhous";
import ActivityInformation from "apps/Activity/ActivityInformation";
import TravelPerson from "apps/TravelPerson/TravelPerson";
import AccountStatement from "apps/AccountStatement/Statement";
import SaleTracker from "ext-apps/SaleTracker/SaleTracker";
import WebTickekSystem from "ext-apps/WebTicketSystem/WebTickekSystem";
import RegisterTicketSystem from "ext-apps/RegisterTicketSystem/RegisterTicketSystem";
import TicketSystem from "apps/TicketSystem/TicketSystem";
import SaleGift from "ext-apps/SaleGift/SaleGift";
import ScreenCrmChart from "apps/CrmChart/ScreenCrmChart";
import StaffSchedule from "apps/StaffAccess/StaffSchedule";
import ConfirmDelivery from "ext-apps/ConfirmDelivery/ConfirmDelivery";
import ScreenAffiliates from "apps/Affiliates/ScreenAffiliates";
import ScreenQrSale from "apps/QrSale/ScreenQrSale";
import PartyScreen from "apps/Party/PartyScreen";
import ScreenMelhousDash from "apps/Melhous/ScreenMelhous";
import ScreenProspect from "ext-apps/CrmFormProspect/ScreenProspect";
import ScreenWeighingInterface from "apps/WeighingInterface/ScreenWeighingInterface";
import ScreenConfirm from "ext-apps/Confirm/ScreenConfirm";

import {
  ShieldCheckIcon,
  CalendarDaysIcon,
  ClipboardDocumentIcon,
  FolderIcon,
  KeyIcon,
  PencilSquareIcon,
  ShoppingCartIcon,
  TruckIcon,
  PencilIcon,
  BookOpenIcon,
  PresentationChartLineIcon,
  PresentationChartBarIcon,
  BuildingOfficeIcon,
  ComputerDesktopIcon,
  FlagIcon,
  CurrencyDollarIcon,
  WrenchScrewdriverIcon,
  InformationCircleIcon,
  CalculatorIcon,
  UsersIcon,
  ScaleIcon,
} from "@heroicons/react/24/solid";

import { CalendarIcon } from "./icons";
import AttendanceControl from "apps/AttendanceControl/AttendanceControl";

const apps = {
  housekeeping: { Component: Housekeeping, Icon: KeyIcon },
  party: { Component: PartyScreen, Icon: UsersIcon },
  hotel_rack: { Component: HotelRack, Icon: CalendarIcon },
  hotel_calendar: { Component: HotelCalendar, Icon: CalendarDaysIcon },
  web_checkin: {
    Component: WebCheckIn,
    Icon: CurrencyDollarIcon,
    addPath: ":id/*",
  },
  sale_order: { Component: SaleOrder, Icon: BookOpenIcon },
  self_service_sale: {
    Component: SelfServiceSale,
    Icon: PencilSquareIcon,
    addPath: ":shop",
  },
  order_notification: {
    Component: OrderNotification,
    Icon: ShoppingCartIcon,
  },
  delivery: { Component: DeliveryPanel, Icon: PencilSquareIcon },
  shipment_internal: { Component: StockRequest, Icon: TruckIcon },
  purchase: { Component: PurchaseOrder, Icon: ShoppingCartIcon },
  purchase_approval: { Component: PurchaseApproval, Icon: ShoppingCartIcon },
  surveillance_schedule: { Component: Schedule, Icon: ShieldCheckIcon },
  inventory: { Component: Inventory, Icon: FolderIcon },
  take_order: { Component: TakeOrder, Icon: FolderIcon },
  order_viewer: { Component: OrderViewer, Icon: FolderIcon },
  sale_call_center: { Component: SaleCallCenter, Icon: PencilIcon },
  purchase_requisition: {
    Component: PurchaseRequisition,
    Icon: ClipboardDocumentIcon,
  },
  crm_fiduprevisora: { Component: CrmFiduprevisora, Icon: FolderIcon },
  sale_turn: { Component: SaleTurn, Icon: FolderIcon },
  consulta_pqr: { Component: ConsultPQR, Icon: FolderIcon },
  crm_sales: { Component: CRMSales, Icon: PresentationChartLineIcon },
  crm_form_prospect: {
    Component: ScreenProspect,
    Icon: PresentationChartLineIcon,
  },
  crm_chart: { Component: ScreenCrmChart, Icon: PresentationChartBarIcon },
  staff_control_access: { Component: StaffControlAccess, Icon: FolderIcon },
  rest_order: { Component: RestOrder, Icon: PencilSquareIcon },
  booking: { Component: Booking, Icon: BuildingOfficeIcon },
  activity: { Component: Activity, Icon: FlagIcon },
  activity_information: {
    Component: ActivityInformation,
    Icon: InformationCircleIcon,
  },
  portal_customer: { Component: PortalCustomer, Icon: ComputerDesktopIcon },
  rental: { Component: ScreenRental, Icon: CurrencyDollarIcon },
  maintenance: { Component: ScreenMaintenance, Icon: WrenchScrewdriverIcon },
  rentalb: { Component: RentalBooking, Icon: CalendarDaysIcon },
  schedule: { Component: StaffSchedule, Icon: FolderIcon },
  web_booking_rental: {
    Component: WebBookingRental,
    Icon: CurrencyDollarIcon,
    addPath: ":id?",
  },
  sale_audit: {
    Component: SaleAudit,
    Icon: CurrencyDollarIcon,
  },
  catalog: {
    Component: Catalog,
    Icon: BookOpenIcon,
  },
  web_booking: {
    Component: WebBookingHotel,
    Icon: CurrencyDollarIcon,
    addPath: ":date?",
  },
  booking_engine: {
    Component: WebBookingHotel,
    Icon: CurrencyDollarIcon,
    addPath: ":date?",
  },
  web_melhous: {
    Component: ScreenMelhous,
    Icon: CurrencyDollarIcon,
  },
  dashboard_melhous: {
    Component: ScreenMelhousDash,
    Icon: CurrencyDollarIcon,
  },
  travel_person: {
    Component: TravelPerson,
    Icon: BookOpenIcon,
  },
  account_statement: {
    Component: AccountStatement,
    Icon: CurrencyDollarIcon,
  },
  sale_tracker: {
    Component: SaleTracker,
    addPath: ":id?",
  },
  ticket: {
    Component: TicketSystem,
    Icon: CalculatorIcon,
  },
  web_ticket_system: {
    Component: WebTickekSystem,
  },
  register_ticket_system: {
    Component: RegisterTicketSystem,
  },
  sale_gift: {
    Component: SaleGift,
    addPath: ":id?",
  },
  sale_confirm_delivery: {
    Component: ConfirmDelivery,
    addPath: ":id?",
  },
  affiliates: {
    Component: ScreenAffiliates,
    Icon: UsersIcon,
  },
  qr_sale: {
    Component: ScreenQrSale,
    Icon: UsersIcon,
  },
  attendance_control: {
    Component: AttendanceControl,
    Icon: UsersIcon,
  },
  confirm: {
    Component: ScreenConfirm,
    Icon: ScaleIcon,
    addPath: ":id?",
  },
};

export default apps;
