import React, { useEffect } from "react";
import date from "date-and-time";
import { signal } from "@preact/signals-react";

import Board from "components/Meta/Board";
import CalendarFilter from "components/Tools/CalendarFilter";
import ModelRentalBooking from "./ModelRentalBooking";
import mainStore from "store/mainStore";
import tool from "tools/functions";

const fmt = "YYYY-MM-DD";
var nextId = -1;

const statusSection = signal(true);
const statusActivities = signal(true);
const filterDay = signal(date.format(new Date(), fmt));

const RentalBooking = (props) => {
  const ctxView = ModelRentalBooking.ctxView(props.config);
  const { updateRecord, updateStore } = mainStore();

  const handleChangeDate = (value) => {
    let daySelected = date.format(new Date(value), "YYYY-MM-DD");
    filterDay.value = daySelected;
  };

  useEffect(() => {
    resetNewRecord();
  }, [filterDay.value, props]);

  const resetNewRecord = async () => {
    nextId = nextId - 1;
    const [toStore, toActive] = await tool.getDefaults(nextId, ctxView);
    updateStore(toStore);
    updateRecord(toActive);
  };

  return (
    <div>
      <CalendarFilter action={handleChangeDate} />

      {statusActivities.value && (
        <div
          className={`flex ${
            statusSection.value ? "flex-row justify-between" : "flex-col"
          }`}
        >
          <div
            className={
              statusSection.value == true
                ? "md:w-[65vw] xl:w-[90vw] "
                : "md:w-12/12 relative pr-10"
            }
          >
            <Board ctxView={ctxView} />
          </div>

          {/* <PanelRight
            position={"right"}
            widgets={["status", "numPeople"]}
            title="INFO BOOKING"
            bgColor={statusSection.value ? "bg-gray-100" : "bg-blue-presik"}
            status={statusSection.value}
            handleChangeStatus={statusSection.value}
            // old
            // handleChangeStatus={setStatusSection}
          ></PanelRight> */}
        </div>
      )}
    </div>
  );
};

export default RentalBooking;
