import store from "store";
import mainStore from "store/mainStore";

const getProduct = (value) => {
  const search = [
    "OR",
    [
      ["template.name", "ilike", `%${value}%`],
      ["template.type", "=", "goods"],
    ],
    [
      ["code", "ilike", `${value}%`],
      ["template.type", "=", "goods"],
    ],
  ];
  return search;
};

const getCategoryUom = (value, rec) => {
  let categoryUom = rec.uom.category;
  const search = [["category", "=", categoryUom]];
  return search;
};

const onChangeProduct = async (record, storeRec) => {
  const activeRecord = mainStore.getState().record;
  if (record.product) {
    const uom = record.product["default_uom."];
    if (uom) {
      record.unit = uom;
      storeRec.unit = uom.id;
    }
    console.log("-----", activeRecord);

    if (activeRecord) {
      storeRec.from_location = activeRecord.customer.customer_location;
      storeRec.to_location = activeRecord.warehouse.input_location;
      storeRec.unit_price = record.product.list_price;
    }
  } else {
    record.unit = null;
    storeRec.unit = null;
    storeRec.from_location = null;
    storeRec.to_location = null;
    storeRec.unit_price = 0;
  }
};

export default {
  model: "stock.move",
  row_selectable: false,
  target: "shipment",
  table_action: ["add", "edit"],
  form_action: ["add", "delete", "edit"],
  webfields: {
    product: {
      type: "many2one",
      model: "product.product",
      // recSearch: getProduct,
      withChange: onChangeProduct,
      required: true,
      attrs: [
        "id",
        "default_uom.name",
        "default_uom.category",
        "name",
        "list_price",
      ],
    },
    quantity: {
      type: "float",
      decimalPlaces: 1,
      default: 1,
      required: true,
      readOnly: true,
    },
    uom: { type: "many2one", model: "product.uom", readOnly: false },
    unit: {
      type: "many2one",
      model: "product.uom",
      recSearch: getCategoryUom,
    },
    company: {
      default: store.get("ctxSession") && store.get("ctxSession").company,
    },
    currency: {
      default: store.get("ctxSession") && store.get("ctxSession").currency,
    },
    from_location: {
      type: "many2one",
      model: "stock.location",
    },
    to_location: {
      type: "many2one",
      model: "stock.location",
    },
    state: {
      type: "char",
      translate: true,
      readOnly: true,
    },
  },
  webtree: [
    { name: "product", width: "60%" },
    { name: "unit", width: "10%" },
    { name: "quantity", width: "20%" },
    { name: "state", width: "20%" },
  ],
  webform: [{ name: "product" }, { name: "quantity" }, { name: "unit" }],
};
