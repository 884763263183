import React from "react";
import { FormattedMessage as FM } from "react-intl";

import { useWizardStore } from "store/wizardStore";

function CardTotal() {
  const { record } = useWizardStore();
  return (
    <div className="w-1/2 mx-auto pt-4 text-center text-2xl text-white bg-sky-900 h-36 rounded-xl">
      <p className="mx-auto">
        <FM id="sale_pos.money_count.total" />
      </p>
      <p className="mt-8 mx-auto font-bold">{record.total || 0}</p>
    </div>
  );
}

export default CardTotal;
