import storeCtx from "store/storeCtx";
import saveStore from "store/saveStore";
import proxy from "api/proxy";

async function actionButton({ button, level, model, ctxView, action }) {
  let { record, store, parentRec, setRecord } = storeCtx(level);
  let res = {
    msg: null,
    data: null,
    error: null,
    button: button,
  };
  if (button.method && typeof button.method !== "string") {
    res = button.method(record);
    return res;
  }
  const size = Object.keys(store).length;
  if (!button || (button?.button_method && size > 1)) {
    const res = await saveStore({
      level,
      model,
      parentRec,
      ctxView,
      actionType: action,
      webfields: ctxView.webfields,
      fieldName: null,
    });
    if (!res.recId && record.id < 0) return res;
    record.id = res.recId ?? record.id;
  }
  let dataToAPI = {
    model: model,
    method: button.button_method ?? button.method,
  };
  let response = {};
  if (button.button_method) {
    dataToAPI.ids = [record.id];
    dataToAPI.method = button.button_method;
    response = await proxy.buttonMethod(dataToAPI);
  } else {
    dataToAPI.args = [store];
    dataToAPI.method = button.method;
    response = await proxy.methodCall(dataToAPI);
    if (response.data?.record) {
      record = response.data.record;
    }
  }
  res = { ...res, ...response };
  if (res.error) {
    return res;
  }
  if (record.id > 0) {
    await setRecord(record.id, model, ctxView);
  }
  return res;
}

export default actionButton;
