import React, { Fragment, useEffect } from "react";
import proxy from "api/proxy";
import tools from "tools/dates";
import { signal } from "@preact/signals-react";

import CharField from "components/MeForm/CharField";
import SelectionField from "../../../components/MeForm/SelectionField";
import StdButton from "components/Buttons/StdButton";
import { checkRequiredForm } from "tools/form";
import { TYPE_DOC } from "../constants";
import funcs from "tools/functions";
import PureModal from "components/Modals/PureModal";
import Invoice from "./Invoice";
import { FormattedMessage as FM, useIntl } from "react-intl";
import { ArrowLeftIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useWizardStore } from "store/wizardStore";
import TextField from "components/MeForm/TextField";
import MethodPayment from "./MethodPayment";

const level = "wizard";
const isLoading = signal(false);
const type_document = signal("");
const id_number = signal("");
const name = signal("");
const lastname = signal("");
const mobile = signal("");
const country = signal("");
const city = signal("");
const email = signal("");
const address = signal("");
const note = signal("");
const msgData = signal(null);
const open = signal(false);
const dataInvoice = signal(null);
const countries = signal([]);
const departments = signal([]);
const cities = signal([]);
const ctxView = {};
ctxView.model = "app.booking";
const ATTRS = {
  level: level,
  ctxView: ctxView,
};

const TODAY = tools.fmtDatetime2Tryton(new Date());

function FormGuest({
  classification,
  reset,
  nights,
  handleView,
  colors,
  data: dataCompany,
  dataChannel,
}) {
  const { store } = useWizardStore();
  const intl = useIntl();
  const bgColorSecundary = colors?.colorSecundary || "bg-yellow-500";
  const bgColorPrimary = colors?.colorPrimary || "bg-blue-presik";
  const textColorPrimary = colors?.colorTextPrimary || "text-yellow-500";
  const storeRequired = ["name", "id_number", "email", "mobile", "country"];

  useEffect(() => {
    dataCountry();
  }, []);

  const dataCountry = async () => {
    const dom = [];
    const { data } = await proxy.search("country.country", dom, ["name", "id"]);
    if (data) {
      const _countries = funcs.recs2Combobox(data);
      countries.value = _countries;
    }
  };

  async function acceptCreate() {
    let idParty = await searchNumberId(store.id_number);
    isLoading.value = true;
    const newStore = { ...store };
    const res = checkRequiredForm(newStore, storeRequired);
    if (!res) {
      msgData.value = "board.missing_required_fields";
      isLoading.value = false;

      return false;
    }
    if (store) {
      let address = [
        [
          "create",
          [
            {
              street: store.address,
              city: store.city || null,
              subdivision: store.subdivision?.id || null,
              country: store.country?.id,
            },
          ],
        ],
      ];

      let contact_mechanisms = [
        [
          "create",
          [
            {
              type: "mobile",
              value: store.mobile,
            },
            {
              type: "email",
              value: store.email,
            },
          ],
        ],
      ];

      let toStore = {
        id: idParty,
        name: `${store.name} ${store.lastname}`,
        id_number: store.id_number,
        type_document: parseInt(store.type_document),
        contact_mechanisms,
      };

      const { data, error } = await proxy.saveQuery({
        model: "party.party",
        storeRec: toStore,
      });
      let id_customer = null;
      if (data?.length > 0) {
        id_customer = data[0];
      } else if (data == null) {
        id_customer = idParty;
      } else {
        alert(error);
      }
      await createBooking(id_customer);

      isLoading.value = false;
    }
    isLoading.value = false;
  }

  const createBooking = async (id_customer) => {
    const { reset: resetWizard, store } = useWizardStore.getState();

    const kindPersonIsCorporate = store?.kindPerson == 2;
    const getRamdonRoom = (rooms) => {
      let index = Math.floor(Math.random() * rooms.length);
      let room = rooms[index];
      rooms.splice(index, 1);
      return room;
    };

    const lines = classification.map((item) => {
      let priceWithoutTax = Math.trunc(item.plan.unit_price);
      let priceWithTax = item.plan.unit_price;
      if (!dataCompany?.excludeTaxes) {
        priceWithTax += item.plan.unit_price * 0.19;
      }
      let discount = store.couponDiscount?.discount || 0;
      let discountedPriceWithTax = priceWithTax * (1 - discount / 100);

      const result = {
        registration_state: "pending",
        product: item.id,
        unit_price: priceWithoutTax,
        room: getRamdonRoom(item.rooms),
        arrival_date: store.arrival_date,
        departure_date: store.departure_date,
        num_adults: item.adults || 1,
        num_children: item.children || 0,
      };

      if (!kindPersonIsCorporate) {
        result.unit_price_w_tax = store.couponDiscount?.type_discount
          ? discountedPriceWithTax
          : Math.trunc(priceWithTax);
      }

      return result;
    });
    let total = classification.reduce((accumulator, item) => {
      let priceWithoutTax = item.plan.unit_price;
      let priceWithTax = item.plan.unit_price;
      if (!dataCompany?.excludeTaxes) {
        priceWithTax += item.plan.unit_price * 0.19;
      }
      return (
        accumulator +
        (kindPersonIsCorporate ? priceWithoutTax : priceWithTax) * nights
      );
    }, 0);

    const toStore = {
      bill_to: "holder",
      lines: lines.map((line) => ["create", [line]]),
      booking_date: TODAY,
      meal_plan:
        store?.ratePlan?.meal_plan || dataCompany?.["price_list."].meal_plan,
      contact: store.name,
      state: "offer",
      channel: dataChannel.id && dataChannel.id ? dataChannel.id : null,
      party: id_customer,
      payment_term: dataCompany?.payment_term,
      notes: (() => {
        let discountNote = store.couponDiscount
          ? `Cupon de descuento aplicado: ${store.couponDiscount?.name} del ${store.couponDiscount?.discount}%`
          : "";

        let userNote = store?.note || "";
        return `${discountNote}${discountNote && userNote ? "\n" : ""}${userNote}`;
      })(),
      taxes_exception:
        dataCompany?.excludeTaxes || kindPersonIsCorporate ? true : false,
      rate_plan: dataChannel?.rate_plan,
      price_list: store?.ratePlan?.price_list,
    };

    const fieldsNames = ["id"];
    const { data, error } = await proxy.create(
      "hotel.booking",
      toStore,
      fieldsNames,
    );

    if (data) {
      const { data: dataBooking } = await proxy.browse(
        "hotel.booking",
        [data[0].id],
        ["id", "rec_name"],
      );
      const { data: resEmail, error } = await proxy.methodInstance({
        model: "hotel.booking",
        method: "send_email_web_booking",
        instance: data[0].id,
      });

      dataBooking[0].total = total;
      dataBooking[0].id_party = id_customer;
      const _dataInvoice = { ...store, ...dataBooking[0] };
      dataInvoice.value = _dataInvoice;
      open.value = true;
      reset();
      resetForm();
      // resetWizard();
    } else {
      alert("error");
    }
  };

  const searchNumberId = async (number) => {
    const { data } = await proxy.search(
      "party.party",
      [["id_number", "=", number]],
      ["name", "id"],
    );
    if (data.length > 0) {
      return data[0].id;
    } else {
      return null;
    }
  };

  const fields = {
    typeDocumentField: {
      name: "type_document",
      label: "app.booking.type_document",
      options: TYPE_DOC,
      translate: true,
      required: true,
    },
    idNumberField: {
      name: "id_number",
      label: "app.booking.id_number",
      required: true,
      type: "char",
    },
    nameField: {
      name: "name",
      label: "app.booking.name",
      required: true,
      type: "char",
      uppercase: true,
    },
    lastnameField: {
      name: "lastname",
      label: "app.booking.lastname",
      required: true,
      type: "char",
      uppercase: true,
    },

    mobileField: {
      name: "mobile",
      label: "app.booking.mobile",
      required: true,
    },
    countryField: {
      name: "country",
      label: "app.booking.country",
      placeholder: "País",
      options: countries.value,
      required: true,
    },

    emailField: {
      name: "email",
      label: "app.booking.email",
      required: true,
    },
    noteField: {
      name: "note",
      label: "app.booking.email",
      required: true,
    },
    addressField: {
      name: "address",
      label: "app.booking.address",
      required: true,
    },
    noteField: {
      name: "note",
      label: "app.booking.note",
      placeholder: intl.formatMessage({ id: "app.booking.note_placeholder" }),
    },
  };
  const resetForm = () => {
    type_document.value = "";
    id_number.value = "";
    name.value = "";
    lastname.value = "";
    mobile.value = "";
    city.value = "";
    email.value = "";
    address.value = "";
    countries.value = [];
    departments.value = [];
    cities.value = [];
  };

  return (
    <div className=" bg-gray-100 p-5 rounded-md shadow-sm mt-10 md:mt-0">
      <div className="flex justify-between">
        <h2 className="text-blue-presik font-semibold  text-lg w-fit pb-2 mb-3  border-b">
          <FM id="app.booking.label_form" />
        </h2>
        <ArrowLeftIcon
          className={`w-10 h-10 p-2 cursor-pointer text-white rounded-full active:scale-95 hover:opacity-90 ${bgColorPrimary}`}
          onClick={handleView}
        />
      </div>

      <form className="grid grid-cols-3 gap-x-3 mb-10  md:p-0 ">
        <div className="col-span-3 md:flex md:space-x-3">
          <SelectionField
            field={fields.typeDocumentField}
            data={type_document}
            attrs={ATTRS}
          />
          <CharField
            field={fields.idNumberField}
            data={id_number}
            attrs={ATTRS}
          />
          <CharField field={fields.nameField} data={name} attrs={ATTRS} />
          <CharField
            field={fields.lastnameField}
            data={lastname}
            attrs={ATTRS}
          />
        </div>

        <div className="col-span-3 md:flex md:space-x-3">
          <CharField field={fields.mobileField} data={mobile} attrs={ATTRS} />

          <SelectionField
            field={fields.countryField}
            data={country}
            attrs={ATTRS}
          />
          <CharField field={fields.emailField} data={email} attrs={ATTRS} />
        </div>
        <div className="col-span-3 md:flex md:space-x-3">
          <TextField field={fields.noteField} data={note} attrs={ATTRS} />
        </div>
        <MethodPayment />

        <div className="py-5 gap-3 col-span-3">
          <StdButton
            style={`mx-auto px-10 ${colors?.callToActions || ""}`}
            content={
              store?.methodPayment?.process_method !== "manual"
                ? "app.booking.btn-form"
                : "app.booking.btn-form-payment"
            }
            color={colors?.colorSecundary ? colors.colorSecundary : "amber"}
            disabled={false}
            loading={isLoading.value}
            onClick={acceptCreate}
            size="w-[200px]"
          />
        </div>
        {msgData.value && (
          <div className="py-5 px-6 rounded-md gap-3 col-span-3 bg-red-300 text-red-800 relative">
            <FM id={msgData.value} />
            <XMarkIcon
              className="w-10 h-10 absolute right-3  top-3 cursor-pointer hover:text-black bg-red-950 rounded-full p-1"
              onClick={() => (msgData.value = null)}
            />
          </div>
        )}
        <PureModal
          open={open.value}
          onClose={() => (open.value = false)}
          backdrop={true}
          width="w-[800px]"
        >
          <Invoice
            data={dataInvoice.value}
            colors={{
              bgColor: bgColorPrimary,
              textColorPrimary: textColorPrimary,
              bgColorSecundary: bgColorSecundary,
            }}
          />
        </PureModal>
      </form>
    </div>
  );
}

export default FormGuest;
