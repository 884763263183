import React, { useEffect, useState } from "react";
import date from "date-and-time";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import proxy from "api/proxy";
import SectionNoData from "components/Tools/SectionNoData";
import ModalWizardActi from "./ModalWizardActi";
import ItemCard from "./ItemCard";
import { SearchRecord } from "components/Tools/SearchRecord";
import ItemCardFixed from "./ItemCardFixed";
import store from "store";
// import ItemCardFixedDark from "./ItemCardFixedDark";
// import ItemCardFixedHorizontal from "./ItemCardFixedHorizontal";

const fieldsActivityKind = [
  "name",
  "image",
  "order",
  "employee.party.name",
  "product.name",
  "product.list_price",
  "product.template.sale_price_w_tax",
  "product.template.list_price",
  "product.sale_price_w_tax",
  "description",
  "product.sale_uom.name",
  "type_programing",
  "id",
  "shops",
];

const ScheduledActivities = (props) => {
  const saleActivity = store.get("saleActivity");
  const session = store.get("ctxSession");
  console.log(props.configuration, " ---------");

  let [activityData, setSearchData] = useState([]);
  const [originalActivityData, setOriginalActivityData] = useState([]);
  let [onOpen, setOnOPen] = useState(false);
  let [ctxViewActive, setCtxViewActive] = useState(null);
  let day = new Date(props.currentDay);
  let dayFormatted = date.format(day, "YYYY-MM-DD");
  let dom = [["date_activity", "=", dayFormatted]];

  const handleChangeActivity = (id) => {
    let domain = [["schedule.id", "=", id]];
    let ctxView_ = { ...props.ctxView };
    ctxView_["domain"] = domain;
    setCtxViewActive(ctxView_);
    setOnOPen(true);
  };

  useEffect(() => {
    getActivities();
  }, [props]);

  const resetSearchData = () => {
    setSearchData(originalActivityData);
  };

  const renderCategories = () => {
    let itemsPerSlide;

    if (window.innerWidth >= 1000) {
      itemsPerSlide = 5;
    } else if (window.innerWidth >= 500) {
      itemsPerSlide = 4;
    } else {
      itemsPerSlide = 2;
    }

    const totalSlides = Math.ceil(activityData.length / itemsPerSlide);
    const slides = [];

    for (let i = 0; i < totalSlides; i++) {
      const start = i * itemsPerSlide;
      const end = start + itemsPerSlide;
      const slideCategories = activityData.slice(start, end);

      slides.push(
        <div
          key={i}
          className="grid md:grid-cols-4 xl:grid-cols-5 gap-3 gap-y-3"
        >
          {slideCategories.map((activity, key) =>
            activity.type === "scheduled" ? (
              <ItemCard
                activity={activity}
                keyId={key}
                key={key}
                handleChangeActivity={handleChangeActivity}
              />
            ) : (
              <ItemCardFixed activity={activity} keyId={key} key={key} />
            ),
          )}
        </div>,
      );
    }

    return slides;
  };

  async function getActivities() {
    let fieldsActivity = [...fieldsActivityKind];

    const { data: fixedActivities } = await proxy.search(
      "sale_activity.kind",
      [
        ["type_programing", "=", "fixed"],
        [
          "OR",
          ["shops", "=", null],
          [
            "shops",
            "in",
            [saleActivity?.shop || props.configuration?.shop?.id],
          ],
        ],
      ],
      fieldsActivity,
    );

    let fields = [
      "date_activity",
      "activities",
      "activities.quota",
      "activities.available",
      "activities.state",
    ];

    for (const field of fieldsActivity) {
      fields.push("kind." + field);
    }

    const { data: activities } = await proxy.search(
      "sale_activity.schedule",
      dom,
      fields,
    );

    if (activities || fixedActivities) {
      const activitiesScheduled = activities.map((activity) => {
        let price = activity["kind."]?.["product."].sale_price_w_tax;
        if (
          activity["kind."]?.["product."]["components"] &&
          activity["kind."]?.["product."]["components"].length > 0
        ) {
          price = activity["kind."]?.["product."]["components"].reduce(
            (total, item) => {
              return total + item["product."].sale_price_w_tax * item.quantity;
            },
            0,
          );
        }
        return {
          id: activity.id,
          name: activity["kind."].name ?? "",
          date_activity: activity.date_activity,
          kind: activity["kind."].id,
          activities: activity["activities."],
          price: price,
          img: activity["kind."]?.image,
          order: activity["kind."]?.order,
          type: "scheduled",
          product: activity["kind."]?.["product."],
        };
      });

      const activitiesFixed = fixedActivities.map((fixed) => {
        let price = fixed["product."]?.sale_price_w_tax;
        if (
          fixed["product."]["components."] &&
          fixed["product."]["components."].length > 0
        ) {
          price = fixed["product."]["components."].reduce((total, item) => {
            return total + item["product."].sale_price_w_tax * item.quantity;
          }, 0);
        }
        return {
          id: fixed.id,
          name: fixed.name,
          code: fixed.code,
          order: fixed.order,
          price: price,
          image: fixed.image,
          productImageUrl: fixed.image,
          product: fixed["product."],
          saleUom: fixed["product."]["sale_uom."],
          type: "fixed",
          description: fixed.description,
        };
      });

      const combinedActivities = [...activitiesScheduled, ...activitiesFixed];

      const sortedActivities = combinedActivities.sort((a, b) => {
        const orderA =
          a.order === null || a.order === ""
            ? Number.MAX_VALUE
            : parseInt(a.order);
        const orderB =
          b.order === null || b.order === ""
            ? Number.MAX_VALUE
            : parseInt(b.order);

        return orderA - orderB;
      });

      setSearchData(sortedActivities);
      setOriginalActivityData(sortedActivities);
    }
  }

  if (activityData.length !== 0 && originalActivityData.length !== 0) {
    return (
      <div
        className={`mt-5 mx-4 mb-3 border-b border-gray-300 pb-12 ${
          props.statusShedule ? "block" : "hidden"
        }`}
      >
        <SearchRecord
          data={activityData}
          setSearchData={setSearchData}
          resetSearchData={resetSearchData}
          title="¿Qué actividad buscas?"
        />

        {props.configuration?.config?.type_view == "carousel" ? (
          <Carousel
            showThumbs={false}
            showStatus={false}
            showIndicators={true}
            emulateTouch={true}
            showArrows={false}
            renderIndicator={(clickHandler, isSelected, index, label) => {
              if (isSelected) {
                return (
                  <li
                    className="custom-dot active"
                    aria-label={`Slide ${label} - Current Slide`}
                  />
                );
              }
              return (
                <li
                  className="custom-dot"
                  onClick={clickHandler}
                  onKeyDown={clickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  title={`${label}`}
                  aria-label={`Slide ${label}`}
                />
              );
            }}
          >
            {renderCategories()}
          </Carousel>
        ) : (
          renderCategories()
        )}

        <ModalWizardActi
          key="button_wizard"
          model="sale_activity.activity"
          label={"Horarios de actividades"}
          ctxView={ctxViewActive}
          onClose={() => setOnOPen(onOpen ? false : true)}
          record={null}
          view={props.view}
          onOpen={onOpen}
        />
      </div>
    );
  } else {
    return (
      <SectionNoData text="No hay actividades programadas para este día" />
    );
  }
};

export default ScheduledActivities;
