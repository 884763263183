import React from "react";
import { signal } from "@preact/signals-react";

import CharInlineField from "components/MeForm/CharInlineField";
import MsgInfo from "./MsgInfo";

function StepOpen(props) {
  const attrs = { level: "wizard" };
  const field = {
    label: "account.statement.start_balance",
    name: "start_balance",
    type: "number",
  };
  const data = signal(0);

  return (
    <div>
      <MsgInfo {...props} />
      <div className="mt-8 mx-24">
        <CharInlineField field={field} attrs={attrs} data={data} />
      </div>
    </div>
  );
}

export default StepOpen;
