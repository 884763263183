// English translation

const en = {
  "account.statement.start_balance": "Start Balance",
  "account.statement.line": "Statement Line",
  "account.statement.line.amount": "Amount",
  "account.statement.line.statement": "Statement",
  "account.statement.line.date": "Date",
  "account.statement.line.number": "Number",
  "account.statement.line.party": "Party",
};

export default en;
