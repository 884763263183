import React, { Fragment, useEffect, useState } from "react";
import { signal } from "@preact/signals-react";

import { useWizardStore } from "store/wizardStore";
import proxy from "api/proxy";
import logoOpenpay from "assets/apps/openpay.png";
import logoAutoCore from "assets/apps/autocore.png";
import logoEfectivo from "assets/apps/efectivo.png";

const methodsPayments = signal([]);
const selectedMethod = signal({});
const METHODS_PAYMENTS = {
  openpay: {
    logo: logoOpenpay,
    method: "openpay_charge",
    title: "Openpay",
    desc: "Pago en línea",
  },
  manual: {
    logo: logoEfectivo,
    method: "manual",
    title: "Pago en hotel",
    desc: "Pago en punto",
  },
  autocore: {
    logo: logoAutoCore,
    method: "autocore_charge",
    title: "Autocore",
    desc: "Pago en línea",
  },
};

const MethodPayment = () => {
  const { updateWizard, store } = useWizardStore();

  const getJournal = async () => {
    const { data: dataJournal } = await proxy.search(
      "account.payment.journal",
      ["active", "=", true],
      ["name", "process_method"],
    );

    if (dataJournal.length !== 0) {
      methodsPayments.value = dataJournal;
      // updateWizard(store, { methodPayment: dataJournal[0] });
    }
  };

  const selectMethodPayment = (item) => {
    updateWizard(store, { methodPayment: item });
    selectedMethod.value = item.process_method;
  };

  useEffect(() => {
    getJournal();
  }, []);

  return (
    <Fragment>
      {methodsPayments.value.length > 0 ? (
        <div className="px-2 my-5 space-y-2 w-full col-span-3">
          <h2 className="font-bold text-zinc-600 text-2xl text-center w-full">
            Medios de pago
          </h2>
          <p className="text-lg text-gray-700">Escoge cómo quieres pagar.</p>
          <div className="flex space-x-4 mt-4">
            {methodsPayments.value.map((item) => {
              const isSelected = selectedMethod.value === item.process_method;

              return (
                <div
                  key={item.id}
                  className={`flex flex-col items-center px-4 py-2 border rounded-lg cursor-pointer shadow-sm min-w-40 ${
                    isSelected
                      ? "border-blue-presik bg-blue-presik text-white"
                      : "border-gray-300 bg-white"
                  }`}
                  onClick={() => selectMethodPayment(item)}
                >
                  <img
                    src={METHODS_PAYMENTS[item.process_method]?.logo || ""}
                    alt={item.process_method}
                    className="h-20"
                  />
                  <span className="text-lg font-semibold capitalize">
                    {METHODS_PAYMENTS[item.process_method]?.title}
                  </span>
                  {METHODS_PAYMENTS[item.process_method]?.desc && (
                    <span className="text-gray-400">
                      {METHODS_PAYMENTS[item.process_method]?.desc}
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default MethodPayment;
