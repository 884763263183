import proxy from "api/proxy";
import useChildStore from "store/childStore";
import useMainStore from "store/mainStore";
import funcs from "tools/functions";

function getQuantityInvoiced(record) {
  let result = 0;
  if (record.invoice_lines) {
    result = Array.from(record.invoice_lines.values()).reduceRight(
      (acc, cur) => acc + cur.quantity,
      0,
    );
  }
  return result;
}

function getAmountInvoiced(record) {
  let result = 0;
  if (record.invoice_lines) {
    result = Array.from(record.invoice_lines.values()).reduceRight(
      (acc, cur) => acc + cur.untaxed_amount,
      0,
    );
  }
  return result;
}

function getQuantityStock(record) {
  let result = 0;
  if (record.invoice_lines) {
    result = Array.from(record.invoice_lines.values()).reduceRight(
      (acc, cur) => acc + cur.quantity,
      0,
    );
  }
  return result;
}

const getProduct = (value) => {
  const search = [
    "OR",
    [
      ["template.name", "ilike", `%${value}%`],
      ["template.salable", "=", true],
    ],
    [
      ["code", "ilike", `${value}%`],
      ["template.salable", "=", true],
    ],
  ];
  return search;
};

const onChangeProduct = async (record, store) => {
  const product = record?.product;

  if (product) {
    let unit = product["sale_uom."];
    let { result, price_w_tax, taxes } = await getSalePrices(record);
    if (product.packing_uom) store.packing_uom = product.packing_uom;
    console.log(result, "esto viene");
    console.log(record, "record");

    record.base_price = record.product.list_price;
    record.unit_price = record.product.list_price;
    record.unit = unit;
    record.unit_price_w_tax = price_w_tax;
    record.amount_w_tax = price_w_tax;
    store.unit_price = record.product.list_price.toString();
    store.unit = unit.id;
    store.taxes = [["add", taxes]];
  }
};

const searchUnit = (value, record) => {
  let dom = [["category", "=", record?.unit?.category]];
  if (value) {
    dom.push(["name", "ilike", `%${value}%`]);
  }
  return dom;
};

const onChangeUnit = async (record, store) => {
  let { unit_price, price_w_tax } = await getSalePrices(record);
  record.unit_price = unit_price;
  record.unit_price_w_tax = price_w_tax;
  store.unit_price = unit_price.toString();
  store.unit_price_w_tax = price_w_tax;
};

const onChangeQty = async (record, store) => {
  let { result, price_w_tax, taxes } = await getSalePrices(record);
  if (record.quantity && record.unit_price_w_tax) {
    const qty = record.quantity;
    record.base_price = record.product.list_price.toString();
    record.unit_price = record.product.list_price.toString();
    record.unit_price_w_tax = price_w_tax;
    store.unit_price = record.product.list_price.toString();
    store.base_price = record.product.list_price.toString();
    if (record.discount) {
      const { amount, discount } = record;
      const amount_ = amount * qty - (amount * qty * discount) / 100;
      record.amount_w_tax = amount_;
    } else {
      record.amount_w_tax = record.unit_price_w_tax * qty;
    }
  }
};

const getSalePrices = async (record) => {
  if (!record.product) return {};
  const parentRec = useMainStore.getState().record;
  const party = funcs.prepareRecord(parentRec.party);
  const store = useChildStore.getState().store;
  let line = {
    product: store.product,
    quantity: Number(store.quantity),
    sale: {
      party: party,
      invoice_party: party,
      price_list: parentRec?.price_list?.id,
    },
  };

  const unit = record.unit?.id ?? record.product["sale_uom."].id;
  const { data: result } = await proxy.methodInstance({
    model: "sale.line",
    method: "on_change_product",
    instance: line,
    args: [],
    ctx: {
      price_list: parentRec?.price_list?.id,
      uom: unit,
      party: parentRec?.party?.id,
      invoice_party: parentRec?.party?.id,
    },
  });

  let taxes = [];
  if (result?.taxes?.add) {
    for (const item of result.taxes.add) {
      taxes.push(item[1].id);
    }
  }
  line.taxes = taxes;
  line.quantity = store.quantity || 1;
  line.unit_price = record.product.list_price;

  const { data: unit_price_w_tax } = await proxy.methodInstance({
    model: "sale.line",
    method: "on_change_with_unit_price_w_tax",
    instance: line,
    args: [],
    ctx: {
      price_list: parentRec?.price_list?.id,
      uom: unit,
      party: parentRec?.party?.id,
      invoice_party: parentRec?.party?.id,
    },
  });

  return { result: result, price_w_tax: unit_price_w_tax, taxes: taxes };
};

const getView = (config) => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    form_action_add: "modal",
    model: "sale.line",
    row_selectable: false,
    table_action: ["add", "edit"],
    form_action: ["add", "delete", "edit"],
    target: "sale",
    // selectable: true,
    webfields: {
      product: {
        type: "many2one",
        model: "product.product",
        recSearch: getProduct,
        withChange: onChangeProduct,
        attrs: [
          "id",
          "list_price",
          "name",
          // "sale_price_taxed",
          "sale_uom.rec_name",
          "description",
          // "base_price",
          config?.modules_activated?.includes("sale_packing_unit")
            ? "packing_uom"
            : "id",
        ],
        required: true,
      },
      unit: {
        type: "many2one",
        model: "product.uom",
        recSearch: searchUnit,
        withChange: onChangeUnit,
        readOnly: true,
        attrs: ["id", "name", "category", "factor"],
      },
      quantity: {
        type: "float",
        withChange: onChangeQty,
        // required: true,
        // decimalPlaces: 2,
        default: 1,
      },
      "invoice_lines.quantity": {
        type: "char",
        function: getQuantityInvoiced,
        search: true,
      },

      unit_price: {
        type: "numeric",
        decimalPlaces: 2,
      },

      "invoice_lines.untaxed_amount": {
        type: "char",
        function: getAmountInvoiced,
        search: true,
      },
      "moves.quantity": {
        type: "char",
        function: getQuantityStock,
        search: true,
      },
      sale_price_taxed: { type: "numeric" },
      list_price: { type: "numeric" },
      unit_price: { type: "numeric", readOnly: true },
      unit_price_w_tax: { type: "numeric", readOnly: true },
      amount_w_tax: { type: "numeric", readOnly: true },
      discount: { type: "numeric", readOnly: true },
    },
    webtree: [
      { name: "product", width: "40%" },
      // { name: "reference", width: "40%" },
      { name: "unit" },
      { name: "quantity" },
      { name: "unit_price" },
      { name: "amount_w_tax" },
    ],
    webform: [
      { name: "product" },
      { name: "unit" },
      { name: "quantity" },
      { name: "unit_price" },
      { name: "amount_w_tax" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
