import { create } from "zustand";

import { getFromId } from "store/common";
import funcs from "tools/functions";
import proxy from "api/proxy";
import store from "store";

export const useWizardStore = create((set, get) => ({
  seqId: 0,
  // model: null,
  // ctxView: null,
  context: {},
  store: {}, // Used for store the wizard data
  record: {}, // Used for render UI purposes
  reset: () => set({ record: {}, store: {}, trigger: null, context: {} }),
  trigger: null,
  setCtx: (ctx) => set(() => ({ context: ctx })),
  setTrigger: (func) => set(() => ({ trigger: func })),
  newRecord: async (ctxView, parentRec) => {
    const session = store.get("ctxSession");
    let [_store, _record] = await funcs.getDefaults(0, ctxView);
    console.log(
      "Esto no esta siendo usado pero si deberia Defaults en wizard...",
      _record,
    );
    const timeStamp = new Date().getTime();
    const hashRec = -(timeStamp + session.user * 10 ** 13);

    set((state) => ({
      seqId: hashRec,
      store: { ..._store, id: hashRec },
      record: { ..._record, id: hashRec },
      context: { ctxView, model: ctxView.model },
    }));
  },
  updateWizard: (rec, store) => {
    const toStore = store ?? rec;
    set((state) => ({
      record: { ...state.record, ...rec },
      store: { ...state.store, ...toStore },
    }));
  },
  reload: async () => {
    const { model, ctxView } = get().context;
    const store = get().store;
    await get().setRecord(store.id, model, ctxView);
  },
  setRecord: async (id, model, ctxView) => {
    const res = await getFromId(id, model, ctxView);
    set({
      record: { ...res },
      store: { id: id },
      context: { model, ctxView },
    });
  },
  save: async () => {
    const { model } = get().context;
    const toSave = get().store;
    const record = get().record;
    const trigger = get().trigger;
    const { data, error } = await proxy.saveQuery({
      model: model,
      storeRec: toSave,
      fieldsNames: ["id", "rec_name"],
    });
    if (!error) {
      let recordId;
      if (toSave.id > 0) {
        recordId = toSave.id;
      } else if (data) {
        if (Array.isArray(data)) {
          recordId = data[0];
        } else {
          const rec = funcs.recordToJs(data);
          recordId = rec.id;
        }
      }
      // We need preprocess data
      set({ store: { id: recordId } });
      if (trigger) {
        trigger(data || [record]);
      }
    }
    return { data, error };
  },
}));
