import React, { Fragment, useState } from "react";
import { FormattedMessage as FM } from "react-intl";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useQuery } from "@tanstack/react-query";

import ModalMsg from "components/Modals/ModalMsg";
import BasicModal from "components/Modals/BasicModal";
import { useWizardStore } from "store/wizardStore";
import DeviceItem from "./DeviceItem";
import proxy from "api/proxy";
import store from "store";

function SelectDevice({ handleCurrent = false }) {
  const [open, setOpen] = useState(true);
  const [currentDevice, setCurrentDevice] = useState(null);
  const { store: wizStore, updateWizard } = useWizardStore();
  const [msg, setMsg] = useState(null);

  async function getDevices() {
    const session = store.get("ctxSession");
    const { data: users } = await proxy.search(
      "res.user",
      [["id", "=", session.user]],
      ["id", "name", "shops"],
    );
    if (users) {
      const { data: devices } = await proxy.search(
        "sale.device",
        [
          ["shop", "in", users[0].shops],
          ["OR", ["code", "!=", null], ["code", "!=", ""]],
        ],
        ["id", "name", "code", "shop.name"],
      );
      const devices_ = devices.filter((item) => {
        if (item.code) {
          return item;
        }
      });
      return devices_;
    }
  }

  const { isLoading, error, data } = useQuery({
    queryKey: ["deviceData"],
    queryFn: getDevices,
  });

  function onSelectDevice(item) {
    setCurrentDevice(item);
    setMsg("Confirma la terminal de venta " + item.name);
  }

  function setCookieDevice() {
    var d = new Date();
    d.setTime(d.getTime() + 360 * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = `device_code=${currentDevice.code};${expires};path=/; Secure`;
    document.cookie = `device_name=${currentDevice.name};${expires}; path=/; Secure`;
    document.cookie = `device_id=${currentDevice.id};${expires};path=/; Secure`;
    document.cookie = `shop_id=${currentDevice["shop."]["id"]};${expires}; path=/; Secure`;
    document.cookie = `shop_name=${currentDevice["shop."]["name"]};${expires};path=/; Secure`;
    setMsg(null);
    updateWizard({ ...wizStore, sale_device: currentDevice });
    handleCurrent ? handleCurrent(currentDevice) : false;
    setOpen(false);
  }

  function onCloseModal() {
    setOpen(false);
  }

  return (
    <BasicModal
      title={"sale_device"}
      open={open}
      onClose={onCloseModal}
      buttons={[]}
      titleSize="text-2xl"
      width="w-full sm:w-4/5 md:w-3/4 lg:w-2/3"
    >
      <p className="text-gray-600 text-xl pt-6 pl-4 mb-6">
        <FM id="account.statement.select_device" />
      </p>
      <div className="space-y-2">
        {data &&
          data.map((item) => (
            <DeviceItem
              key={item.code}
              item={item}
              onSelectDevice={onSelectDevice}
            />
          ))}
      </div>
      {msg && (
        <ModalMsg
          open={true}
          type="ask"
          msg={msg}
          buttons={["cancel", "ok"]}
          onClose={() => setMsg(null)}
          onOK={setCookieDevice}
        />
      )}
    </BasicModal>
  );
}

export default SelectDevice;
