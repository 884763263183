import store from "store";

import fetchAPI from "./fetchAPI";
import func from "tools/functions";
import env from "../env";

function getSession() {
  // // Side options: app or dash
  const [, side, db] = location.pathname.split("/");
  let session;
  if (db === "login" || (side && side === "dash")) {
    session = store.get("ctxSession");
  } else {
    session = store.get("ctxVirtualSession");
    if (!session) {
      session = { db: db, session: env.api_key };
    }
  }
  return session;
}

const save = async (data) => {
  data["context"] = getSession();
  let opts = {
    method: "POST",
    uri: "/write",
    body: data,
  };

  return await fetchAPI(opts);
};

/**
 *
 * @param {String} model model tryton
 * @param {storeRec} Record  record to write in tryton
 * @returns
 */
const saveQuery = async ({ model, storeRec, fieldsNames }) => {
  const write = storeRec.id > 0;
  const storeRec_ = func.recToTryton(storeRec);
  let args = {
    model: model,
    fields_names: fieldsNames,
    context: getSession(),
  };

  let opts = {
    method: "POST",
    body: args,
  };

  if (write) {
    args.values = storeRec_;
    args.ids = [storeRec.id];
    opts.uri = "/write";
  } else {
    args.record = storeRec_;
    opts.uri = "/create";
  }

  opts.body = args;
  return await fetchAPI(opts);
};

const saveMany = async (data) => {
  data["context"] = store.get("ctxSession");
  let opts = {
    method: "POST",
    uri: "/save_many",
    body: data,
  };

  return await fetchAPI(opts);
};

export const remove = async (data) => {
  data["context"] = store.get("ctxSession");
  let opts = {
    method: "POST",
    uri: "/delete",
    body: data,
  };

  return await fetchAPI(opts);
};

const action = async (model, record, action) => {
  const context = store.get("ctxSession");
  let opts = {
    method: "PUT",
    uri: "/action",
    body: { model, record, action, context },
  };

  return await fetchAPI(opts);
};

/**
 * @param {string} model - Tryton model
 * @param {Array} dom - Tryton domain
 * @param {Array} fields - Fields values to return
 * @param {Number} offset - If offset or limit are set, the result starts at the offset and has the length of the limit
 * @param {Number} limit - Limit of records to return optional
 * @param {Array} order - Order of records to return ej: [['columName', 'ASC']]
 * @param {string} database - Name database optional
 * @returns {Promise}
 */

const search = async (model, dom, fields, limit, order, offset, database) => {
  let domain = dom;
  const context = getSession();
  let fields_names = fields;
  let opts = {
    method: "POST",
    uri: "/search",
    body: { model, domain, fields_names, limit, order, offset, context },
  };
  return await fetchAPI(opts, database);
};

/**
 * @param {String} model - Tryton model
 * @param {Array} ids - Tryton list ids
 * @param {Array} fields - Fields values to return
 */
const browse = async (model, ids, fields) => {
  const context = getSession();
  let fields_names = fields;
  let opts = {
    method: "POST",
    uri: "/browse",
    body: { model, ids, fields_names, context },
  };
  return await fetchAPI(opts);
};

const browseQuery = async (args) => {
  const context = getSession();
  const [, model, ids, fields_names] = args.queryKey;
  if (ids[0] < 0) {
    return;
  }
  let opts = {
    method: "POST",
    uri: "/browse",
    body: { model, ids, fields_names, context },
  };
  return await fetchAPI(opts);
};

const searchQuery = async (args) => {
  const [, model, domain, fields_names, order, limit, offset] = args.queryKey;
  if (!domain || !model || !fields_names) {
    return [];
  }

  const context = getSession();
  let opts = {
    method: "POST",
    uri: "/search",
    body: { model, domain, fields_names, order, limit, offset, context },
  };
  return await fetchAPI(opts);
};

/**
 * count number records for search domain
 * @param {model, domain} args
 * @returns
 */
const searchQueryCount = async (args) => {
  const [, model, domain] = args.queryKey;
  const context = getSession();
  let opts = {
    method: "POST",
    uri: "/search_count",
    body: { model, domain, context },
  };
  return await fetchAPI(opts);
};

/**
 * arg.queryKey => {model, method, args}
 */
const methodQuery = async (arg) => {
  const [, model, method, args] = arg.queryKey;
  const context = getSession();
  let opts = {
    method: "POST",
    uri: "/method",
    body: { model, method, args, context },
  };

  return await fetchAPI(opts);
};

// deprecated
const searchSelection = async (model, domain, selection, fields) => {
  let opts = {
    method: "POST",
    uri: "/search_selection",
    body: { model, domain, selection, fields },
  };

  return await fetchAPI(opts);
};

/**
 * args = {model, method, ids}
 */
const buttonMethod = async (args) => {
  const ctx = args.ctx ?? {};
  args["context"] = Object.assign(getSession(), ctx);
  let opts = {
    method: "POST",
    uri: "/button_method",
    body: args,
  };

  return await fetchAPI(opts);
};

const searchRecord = async (model, clause, fields) => {
  let opts = {
    method: "POST",
    uri: "/search_record",
    body: { model, clause, fields },
  };

  return await fetchAPI(opts);
};

/**
 *
 * @param {String} model - Define model string tryton
 * @param {Array} fields_names - optional define fields names for search in model
 * @param {String} database - optional define name database
 * @param {Object} ctx - optional define name database
 * @returns
 */

const getFields = async (model, fields_names, database, ctx = {}) => {
  const context = Object.assign(getSession(), ctx);
  let opts = {
    method: "POST",
    uri: "/fields_get",
    body: { model, fields_names, context },
  };

  return await fetchAPI(opts, database);
};

const report = async ({ report, data, records }) => {
  const context = getSession();
  let opts = {
    method: "POST",
    uri: "/report",
    body: { report, records, data, context },
  };
  const { data: _report, error } = await fetchAPI(opts);
  const [oext, content, direct, name] = _report;
  const reportUrl = func.base64ToBlobUrl(content, oext);
  const nameFile = name + "." + oext;
  return { reportUrl, nameFile, error };
};

/**
 * @param {String} model - Tryton model
 * @param {Array} rec - Record to create in tryton
 * @param {Array} fieldsNames - Fields values to return
 */
const create = async (model, rec, fieldsNames) => {
  let context = getSession();

  let toCreate = {
    model: model,
    record: rec,
    context: context,
  };
  if (fieldsNames) {
    toCreate.fields_names = fieldsNames;
  }

  let opts = {
    method: "POST",
    uri: "/create",
    body: toCreate,
  };

  return await fetchAPI(opts);
};

const fastLogin = async (credentials) => {
  let opts = {
    method: "POST",
    uri: "/fast_login",
    body: credentials,
  };
  return await fetchAPI(opts, credentials.database);
};

const loginCustomer = async (credentials) => {
  let opts = {
    method: "POST",
    uri: "/login_web",
    body: credentials,
  };
  return await fetchAPI(opts, credentials.database);
};

const logout = async () => {
  let context = getSession();
  let opts = {
    method: "POST",
    uri: "/logout",
    body: { context },
  };
  return await fetchAPI(opts);
};

// const get_form = async (name) => {
//   let opts = {
//     method: "GET",
//     uri: `/webform?model=${name}`,
//   };
//
//   return await fetchAPI(opts);
// };

const getModels = async () => {
  const session = store.get("ctxSession");
  if (session) {
    let opts = {
      method: "GET",
      uri: `/models?user=${session.user}`,
    };

    return await fetchAPI(opts);
  }
};

const getTranslations = async () => {
  const opts = {
    method: "GET",
    uri: "/translations",
  };

  return await fetchAPI(opts);
};

/**
 * @param {Array} args - {model, method, args}
 */
const method = async (args) => {
  console.log(
    "Warning: this method name in proxy is deprecated please use instead methodCall",
  );
  args["context"] = getSession();
  let opts = {
    method: "POST",
    uri: "/method",
    body: args,
  };

  return await fetchAPI(opts);
};

/**
 * @param {Array} args - {model, method, args}
 */
const methodCall = async (args) => {
  // We need change returned type of methodCall to {res, error} if
  // error then we must to open a modal with message in a parent Component
  args.context = getSession();
  let opts = {
    method: "POST",
    uri: "/method",
    body: args,
  };

  return await fetchAPI(opts);
};

const execute = async (model, method, args) => {
  const context = JSON.stringify(store.get("ctxSession"));
  const _body = { model, method, args, context };
  const opts = {
    method: "POST",
    uri: "/execute",
    body: _body,
  };

  return await fetchAPI(opts);
};

/**
 * validate access to model tryton with mode [write, read, create, delete]
 * @param {String} model_name - name model tryton required
 * @param {String} mode -  string option to validate ex: read
 * @return {Boolean}  true, false
 */
export const checkAccess = async ({ model_name, mode }) => {
  const context = JSON.stringify(store.get("ctxSession"));
  const body_ = {
    model: "ir.model.access",
    method: "check",
    kwargs: { model_name: model_name, mode: mode, raise_exception: false },
    context: context,
  };

  let opts = {
    method: "POST",
    uri: "/method",
    body: body_,
    api: "tryton",
  };
  return await fetchAPI(opts);
};

/**
 * validate access to model tryton with mode [write, read, create, delete]
 * @param {String} model - name model tryton required
 */
export const methodInstance = async ({
  model,
  method,
  instance,
  ctx = {},
  args = [],
  kwargs = {},
  fieldsNames = [],
}) => {
  const context = Object.assign(getSession(), ctx);
  const body_ = {
    model: model,
    method: method,
    instance: instance,
    fields_names: fieldsNames,
    args: args,
    kwargs: kwargs,
    context: context,
  };

  let opts = {
    method: "POST",
    uri: "/method_instance",
    body: body_,
    api: "tryton",
  };
  return await fetchAPI(opts);
};

export default {
  save,
  saveMany,
  remove,
  search,
  searchSelection,
  searchRecord,
  browse,
  browseQuery,
  searchQuery,
  searchQueryCount,
  methodQuery,
  saveQuery,
  execute,
  method,
  methodCall,
  getTranslations,
  getModels,
  action,
  logout,
  create,
  report,
  buttonMethod,
  getFields,
  checkAccess,
  getSession,
  methodInstance,
  fastLogin,
  loginCustomer,
};
