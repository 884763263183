import React from "react";
import { signal } from "@preact/signals-react";

import mainStore from "store/mainStore";
import store from "store";
import { useIdSeq } from "store/idSeq";
import { CurrencyDollarIcon, PlusCircleIcon } from "@heroicons/react/20/solid";
import imgDefault from "../../../assets/img/default-image.jpg";
import ModelSale from "../models/ModelSale";
import func from "../../../tools/functions";
import proxy from "api/proxy";
import Spinner from "components/Tools/Spinner";

const loading = signal(false);

const ItemCardFixed = ({ activity, keyId }) => {
  const {
    store: storeRecord,
    updateRecord,
    updateStore,
    setRecord,
    record: activeRecord,
  } = mainStore();
  const { seq, increment } = useIdSeq();
  const session = store.get("ctxSession");
  const ctxViewSale = ModelSale.ctxView();

  const addActivity = async (record) => {
    loading.value = true;
    const _storeRecord = { ...storeRecord };
    let _activeRecord = { ...activeRecord };

    const product = activity.product;
    const sequence = seq;
    let line_ = {
      id: sequence,
      quantity: 1,
      unit: record.saleUom,
      amount: activity.price,
      base_price: product["list_price"].toFixed(4),
      unit_price: product["list_price"].toFixed(4),
      discount: 0,
      product: product,
    };

    let lineStore_ = {
      id: sequence,
      unit: record.saleUom?.id,
      product: product.id,
      quantity: 1,
      base_price: String(product["list_price"]),
      unit_price: product["list_price"].toFixed(4),
    };

    let lines = _activeRecord.lines;
    if (!_storeRecord.lines) {
      _storeRecord.lines = new Map();
      _storeRecord.lines.set("create", new Map());
    }
    let to_create = _storeRecord.lines.get("create");
    _storeRecord.salesman = session.user_employee || null;
    to_create.set(sequence, lineStore_);
    lines.set(sequence, { ...line_ });
    increment(sequence + -1);
    record.lines = lines;
    store.lines = _storeRecord.lines;
    const totalAmount = [...lines.values()].reduce(
      (accumulator, currentValue) => {
        const amount = parseFloat(currentValue.amount);
        return accumulator + amount;
      },
      0,
    );

    _activeRecord.total_amount = totalAmount.toFixed(2);

    updateRecord(_activeRecord);
    updateStore(_storeRecord);

    await createSaleRecord(_storeRecord);
  };
  const createSaleRecord = async (store) => {
    const _storeRecord = store;
    const _data = func.recToTryton(_storeRecord);
    const args = {
      model: "sale.sale",
      method: "send_order",
      args: [_data],
    };
    const { data: res, error } = await proxy.methodCall(args);
    if (!error) {
      const recordId = _storeRecord.id >= 0 ? _storeRecord.id : res.record.id;
      setRecord(recordId, "sale.sale", ctxViewSale);
      loading.value = false;
    } else {
      console.log(error);
    }
  };

  const ContainerLoading = () => {
    return (
      <div className="w-full h-full bg-blue-presik/80 absolute left-0 top-0 flex justify-center items-center">
        <Spinner color="white" />
      </div>
    );
  };
  return (
    <div
      key={keyId}
      className={`group relative h-36 hover:shadow-md mt-4 ${
        keyId % 2 == 1 ? "bg-blue-presik text-white" : "bg-gray-200"
      } shadow-lg relative ${!loading.value ? "active:scale-95" : ""}  cursor-pointer overflow-hidden text-bluePresik  rounded-md flex `}
      onClick={() => loading.value || addActivity(activity)}
      title={activity?.description}
    >
      <div className="w-2/5 flex items-center overflow-hidden">
        <img
          src={activity.image || imgDefault}
          className=" w-full h-full object-cover relative -left-1 animate-fade-right animate-ease-linear group-hover:scale-110 duration-100 transition transform "
        />
      </div>

      <div className="p-3 flex flex-col justify-between w-3/5 max-w-3/5">
        <div className="space-y-1 divide-y divide-gray-300" id="headerCard">
          <span className="text-sm font-semibold uppercase line-clamp-2 text-left">
            {activity.name}
          </span>
          <span className="flex pt-2 items-center space-x-1.5 align-middle">
            <CurrencyDollarIcon className="w-5 " />{" "}
            <span>{activity.price.toLocaleString()}</span>
          </span>
        </div>
        <div className="flex flex-col items-start ">
          <span className="first-letter:capitalize font-medium line-clamp-2 text-left w-[80%]">
            {activity?.description}
          </span>

          <PlusCircleIcon
            className={`w-10  absolute right-1.5 bottom-1.5 ${
              keyId % 2 == 0 ? "text-bluePresik" : "text-white"
            } bg group-hover:scale-125 transform duration-150 `}
          />
          {loading.value ? <ContainerLoading /> : null}
        </div>
      </div>
    </div>
  );
};

export default ItemCardFixed;
