import React, { useState } from "react";
import { FormattedMessage as FM } from "react-intl";
import date from "date-and-time";
import { signal } from "@preact/signals-react";
import { useQuery } from "@tanstack/react-query";

import DateField from "components/MeForm/DateField";
import ModalWizard from "components/Modals/ModalWizard";
import Sheet from "./Sheet";
import WizardUpdate from "./WizardUpdate";
import proxy from "api/proxy";
import dates from "tools/dates";

const selectedDate = signal(new Date());

function RateCalendar(props) {
  const [open, setOpen] = useState(false);

  const getDataSheet = async () => {
    const start_date = dates.fmtDate2Tryton(selectedDate.value);
    const ctxArgs = {
      model: "hotel.calendar",
      method: "get_sheet",
      args: [
        {
          start_date: start_date,
          oc: props.oc,
        },
      ],
    };

    const { data } = await proxy.methodCall(ctxArgs);
    return data;
  };

  const { isLoading, data, error, refetch } = useQuery({
    queryKey: ["sheetData"],
    queryFn: getDataSheet,
  });
  console.log("data ....", data);

  const onSave = async (value) => {
    const model = "hotel.calendar.line.price";
    const storecRec = {
      id: value.id,
      unit_price: value.unit_price,
    };
    if (value.id) {
      const data = {
        model: model,
        storeRec: storecRec,
      };
      await proxy.saveQuery(data);
    }
  };

  function onChangeDate(field, value) {
    selectedDate.value = date.parse(value, "YYYY-MM-DD");
    refetch();
  }

  const attrsDate = {
    level: "wizard",
  };

  const field = {
    label: "hotel.calendar.select_date",
    name: "calendar_date",
    labeled: false,
    onChange: onChangeDate,
  };

  const wizAttrs = {
    style: "w-44",
    parentRec: data,
  };

  const wizRangeAttrs = {
    style: "w-44",
    parentRec: data,
  };

  const wizField = {
    label: "hotel.calendar.update_range",
    model: "hotel.calendar.wizard_range",
    icon: "fi fi-br-list-check",
    color: "blue",
    Component: WizardUpdate,
  };

  return (
    <div className="w-full h-full px-4 overflow-x-hidden">
      <h2 className="flex text-3xl font-bold text-sky-700 p-3 my-auto">
        <FM id="hotel.calendar.rates" />
      </h2>
      <div className="flex gap-4 my-2">
        <div className="flex">
          <DateField attrs={attrsDate} data={selectedDate} field={field} />
        </div>
        <div className=" my-auto">
          {data && <ModalWizard field={wizField} attrs={wizAttrs} />}
        </div>
      </div>
      {data && <Sheet onSave={onSave} records={data} />}
    </div>
  );
}

export default RateCalendar;
