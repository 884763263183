import React, { useState, useEffect, Fragment } from "react";
import { PhoneArrowUpRightIcon } from "@heroicons/react/20/solid";

import iconWhatsapp from "assets/apps/whatsapp_icon.png";

const CallAction = ({ value = "", type = "call" }) => {
  const [formattedValue, setFormattedValue] = useState(value);

  const formatPhoneNumber = (phoneNumber) => {
    const formattedNumber = phoneNumber.replace(/[^0-9]/g, "");
    return formattedNumber;
  };

  useEffect(() => {
    setFormattedValue(formatPhoneNumber(value));
  }, [value]);

  const makeCall = () => {
    window.location.href = `tel:${formattedValue}`;
  };

  const ButtonCall = () => {
    return (
      <Fragment>
        <span
          className="flex group cursor-pointer rounded-md overflow-hidden max-w-max justify-between bg-gray-100 "
          onClick={makeCall}
        >
          <span className="px-3 py-1  min-w-[100px]">{formattedValue}</span>
          <PhoneArrowUpRightIcon className="w-6  bg-blue-presik  text-white p-0.5 px-1 group-hover:text-black group-hover:bg-lime-500" />
        </span>
        <span className="cursor-pointer">
          <img src={iconWhatsapp} className="w-5" />
        </span>
      </Fragment>
    );
  };

  if (type === "call") {
    return (
      <div className="flex justify-center space-x-3 items-center align-middle">
        {formattedValue == 0 ? "Null" : <ButtonCall />}
      </div>
    );
  }

  return <span>{formattedValue}</span>;
};

export default CallAction;
