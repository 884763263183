import React, { useRef } from "react";

import FormField from "./FormField";
import upStore from "store/upStore";
import { classNames, colors } from "tools/ui";

const style =
  "h-10 rounded-md shadow-sm w-full text-right py-2 px-3 text-gray-700 border border-slate-300 focus:outline-none focus:border focus:border-cyan-500";

const PATTERN = /^-?\d*\.?\d{0,6}$/;
const formatter = Intl.NumberFormat("en-US");

function FloatField(props) {
  const { field, attrs, data } = props;
  const { name, readOnly, placeholder, icon } = field;
  const { level } = attrs;

  let initValue = data.value;
  if (initValue) {
    if (typeof initValue === "string" && !initValue.endsWith("."))
      initValue = formatter.format(data.value);
    else if (typeof initValue !== "string") {
      initValue = formatter.format(String(data.value));
    }
  }

  function onChange(event) {
    let _value = event.target.value.replaceAll(",", "");
    const validNumber = PATTERN.test(_value);
    if (!validNumber) {
      return;
    }

    data.value = _value;

    // let formattedValue = parseFloat(_value).toFixed(field.decimalPlaces || 0);
    // if (percent) {
    //   formattedValue /= 100;
    // }
    // upStore(level, {
    //   fieldName: name,
    //   value: formattedValue,
    //   // value: formattedValue,
    //   field: field,
    // });
  }

  const onBlur = async () => {
    let _value = data.value;

    if (typeof _value === "string") {
      _value = _value.replaceAll(",", "");
      if (_value === "") _value = 1;
      let valueFloat = parseFloat(_value).toFixed(field.decimalPlaces || 0);
      data.value = valueFloat;
      await upStore(level, {
        fieldName: name,
        value: Number(valueFloat),
        // value: formattedValue,
        field: field,
      });
    }
  };

  let _color = readOnly ? colors.readOnly : field.color ?? "bg-white";

  return (
    <FormField {...field}>
      <div className="flex">
        <input
          id={name}
          name={name}
          className={classNames(style, _color)}
          type="text"
          value={initValue}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
          placeholder={placeholder ?? ""}
        />
        {icon || null}
      </div>
    </FormField>
  );
}

export default FloatField;
