import React from "react";

import StdButton from "components/Buttons/StdButton";
import { useBtnLoading } from "store/btnLoading";

function ButtonForm(props) {
  // This option is useless because factoryField resolve
  const { field, attrs } = props;
  const {
    name,
    label,
    disabled,
    color,
    iconRight,
    iconLeft,
    loading = true,
    style = false,
  } = field;
  const { handleButton } = attrs;
  const { loadingBtn, setButtonLoading } = useBtnLoading();

  async function onClick() {
    if (loading) {
      setButtonLoading(true);
    }
    handleButton(name);
  }

  const _color = color ?? "green";
  return (
    <div className="py-4">
      <StdButton
        key={name}
        name={name}
        onClick={onClick}
        color={_color}
        content={label}
        disabled={disabled}
        loading={loading ? loadingBtn : false}
        iconRight={iconRight || false}
        iconLeft={iconLeft || false}
        style={style}
      />
    </div>
  );
}

export default ButtonForm;
