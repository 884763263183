import proxy from "api/proxy";
import store from "store";

import ModelSaleLine from "./ModelSaleLine";
import ModelParty from "./ModelParty";
import WizardAddPayment from "../WizardAddPayment";
import ProductDiscount from "../components/ProductDiscount";
import { ModalWizzardFolios } from "../components/ModalWizardFolios";
import { ModalWizzardEvents } from "../components/ModalWizardEvents";
import { generateInvoiceHTML } from "../templates/invoice";
import funcs from "tools/functions";
import tools from "tools/dates";

const stateColors = {
  draft: "gray",
  cancelled: "rose",
  quotation: "amber",
  processing: "indigo",
  transferred: "sky",
  done: "lime",
};

const onPrintBrowser = async (record) => {
  const model = "sale.sale";
  const fieldsActi = ["id", "time_start", "time_end"];
  const session = await store.get("ctxSession");
  const imageLogo = funcs.base64ToBlobUrl(session.company_logo, "png");

  // const logo = session?.config?.["company."]?.logo;
  const terminal = funcs.getCookie();
  const args = { user_id: session.user, device_id: Number(terminal.device_id) };

  const { data: ctxPrinting } = await proxy.methodCall({
    model: model,
    method: "get_printing_context",
    args: [args],
  });

  const argSale = {
    type_doc: "invoice",
    sale_id: record.id,
  };
  const { data: sale } = await proxy.methodCall({
    model: model,
    method: "get_data",
    args: [argSale],
  });

  let _sale = { ...record, ...sale };
  _sale.order = sale?.order;
  _sale.payment_term = sale?.payment_term;
  // _sale.create_date = new Date(record.sale_date);
  // _sale.create_date = _sale.create_date.toLocaleDateString();
  // _sale.party_address = record.party.address;
  if (_sale.courtesy) {
    _sale.total_discount_amount = _sale["lines."].reduce((total, item) => {
      return total + item.base_price * item.quantity;
    }, 0);
  }

  let htmlTax;
  let infoTransferRoom = "";
  let infoTransfer = "";

  const htmlContent = await generateInvoiceHTML(
    _sale,
    ctxPrinting,
    sale,
    record,
    htmlTax,
    infoTransferRoom,
    infoTransfer,
    imageLogo,
  );
  const renderDocument = await getRenderDocument(htmlContent);
  await new Promise((resolve) => setTimeout(resolve, 600));
  renderDocument.print();
};

async function getRenderDocument(htmlContent) {
  const generatePrint = window.open("", "", "width=500,height=600");
  generatePrint.document.open();
  generatePrint.document.write(htmlContent);
  generatePrint.document.close();
  return generatePrint;
}

const onChangeLines = (activeRecord) => {
  if (activeRecord.lines) {
    const totalAmount = Array.from(activeRecord.lines.values()).reduce(
      (total, item) => {
        let sale_price = item.unit_price_w_tax ?? item.product.sale_price_w_tax;
        return total + item.quantity * sale_price;
      },
      0,
    );
    activeRecord.total_amount = totalAmount.toFixed(0);
  }
  return activeRecord;
};

const visiblePrint = (name, record) => {
  let res = false;
  if (
    (record.state === "draft" && record.invoice_type == "M" && record.number) ||
    record.state === "processing" ||
    record.state === "transferred" ||
    record.state === "done"
  ) {
    res = true;
  }
  return res;
};

const visibleFolio = (name, record, config) => {
  let res = false;
  if (
    record.state === "draft" &&
    record.lines &&
    record.lines.size > 0 &&
    config?.modules_active?.includes("hotel")
  ) {
    res = true;
  }
  return res;
};

const visibleTransfer = (record) => {
  let res = false;
  if (record.state === "draft" && record.lines && record.lines.size > 0) {
    res = true;
  }
  return res;
};

const disabledField = (record) => {
  let res = false;
  if (record.state !== "draft") {
    res = true;
  }
  return res;
};

const disabledReservation = (record) => {
  let res = true;
  const session = store.get("ctxSession");
  const sellerPermits = session.groups.some(
    (group) => group.name === "Venta en Reserva",
  );
  if (record.state !== "draft" || sellerPermits) {
    res = false;
  }
  return res;
};

const visibleDiscount = (name, record, config) => {
  return record.state !== "draft" ? false : true;
};
const visibleResidual = (name, record, config) => {
  return record.state == "draft" ? false : true;
};

const visiblePay = (name, record) => {
  let res = false;
  if (
    record.lines &&
    record.lines.size > 0 &&
    record.party &&
    record.residual_amount != 0 &&
    record.state !== "transferred" &&
    record.state !== "draft"
  ) {
    res = true;
  }
  return res;
};

const visibleCourtesy = (name, record, config) => {
  let res = false;
  if (
    record.lines &&
    record.lines.size > 0 &&
    record.party &&
    record.residual_amount != 0 &&
    !["transferred", "draft"].includes(record.state) &&
    config?.allow_courtesy
  ) {
    res = true;
  }
  return res;
};

const visibleQuote = (name, record) => {
  let res = false;
  if (record.state === "draft" && record.lines && record.lines.size > 0) {
    res = true;
  }

  return res;
};

const onChangeParty = async (record, storeRec) => {
  if (record.party) {
    const { data } = await proxy.search(
      "party.party",
      [["id", "=", record.party.id]],
      ["addresses"],
    );
    if (data && data[0]?.addresses?.length > 0) {
      storeRec.shipment_address = data[0].addresses[0];
      storeRec.invoice_address = data[0].addresses[0];
    } else {
      storeRec.shipment_address = null;
      storeRec.invoice_address = null;
    }
  }
};

const getFilters = () => {
  return {
    draft: [["state", "=", "draft"]],
    done: [["state", "=", "done"]],
    transferred: [["state", "=", "transferred"]],
  };
};
const today = tools.dateToday();

const getView = (config) => {
  const session = store.get("ctxSession");
  let DictCtxView = {
    model: "sale.sale",
    activeSearch: true,
    filters: getFilters,
    domain: [
      ["shop", "=", parseInt(session.shop)],
      ["sale_date", ">=", `${today}`],
    ],
    form_action: ["save", "edit", "add"],
    table_action: ["edit"],
    pagination: [],
    limit: 20,
    tags: {
      state: stateColors,
    },
    selectable: null, // Options for table rows: null, multi, one
    title: { field: "numeric", model: true },
    webfields: {
      id: { type: "integer", readOnly: true },
      name: { type: "name", readOnly: true },
      number: { type: "char", readOnly: true, searchable: true },
      "party.id_number": {
        type: "char",
        searchable: true,
      },
      "party.mobile": {
        type: "char",
        call: ["mobile", "whatsapp"],
      },
      party: {
        type: "many2one",
        model: "party.party",
        ctxView: ModelParty.ctxView(),
        required: true,
        searchable: true,
        withChange: onChangeParty,
        // default: defaultParty,
        attrs: ["account_receivable", "addresses"],
        readOnly: disabledField,
      },
      create_uid: {
        type: "many2one",
        model: "res.user",
        readOnly: true,
      },
      courtesy: {
        type: "boolean",
        readOnly: true,
      },
      payment_term: {
        type: "many2one",
        required: true,
        model: "account.invoice.payment_term",
        // options: getOptions(),
        translate: true,
        // default: defaultPaymentTerm,
        readOnly: true,
      },
      invoice_address: {
        type: "many2one",
        model: "party.address",
        recSearch: () => [],
      },

      sale_date: {
        type: "date",
        readOnly: true,
        default: () => new Date(),
        required: true,
      },

      source: {
        type: "many2one",
        model: "sale.source",
        readOnly: true,
      },

      invoice_method: {
        type: "char",
        required: false,
      },
      state: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "draft",
        tags: stateColors,
      },

      residual_amount: {
        type: "numeric",
        readOnly: true,
        rounded: true,
        visible: visibleResidual,
      },
      paid_amount: {
        type: "numeric",
        readOnly: true,
        rounded: true,
        visible: visibleResidual,
      },
      shop: {
        type: "many2one",
        model: "sale.shop",
        readOnly: true,
        required: true,
      },
      reservation: {
        type: "boolean",
        readOnly: disabledReservation,
      },
      total_amount: {
        type: "numeric",
        readOnly: true,
        rounded: true,
        // function: onChangeLines,
      },
      comment: {
        type: "char",
        searchable: true,
        readOnly: disabledField,
      },
      description: {
        type: "char",
        searchable: true,
        readOnly: disabledField,
      },
      sale_device: {
        type: "many2one",
        model: "sale.device",
        recSearch: () => [],
        required: true,
        // default: getSaleDevice,
        readOnly: true,
      },

      price_list: {
        type: "many2one",
        recSearch: () => [],
        model: "product.price_list",
        required: false,
        readOnly: true,
      },

      lines: {
        type: "one2many",
        model: "sale.line",
        ctxView: ModelSaleLine.ctxView(config),
        withChange: onChangeLines,
      },
      agent: {
        type: "many2one",
        model: "commission.agent",
        searchable: true,
        readOnly: true,
      },
      pay: {
        type: "button",
        button_method: "",
        visible: ProductDiscount,
        color: "blue",
        onSuccessMsg: "ORDEN COMANDADA...!",
      },
      add_payment: {
        type: "button_wizard",
        Component: WizardAddPayment,
        color: "lime",
        icon: "fi fi-rr-add",
        style: "w-10",
        visible: visiblePay,
        resetWizard: false,
      },
      folios: {
        type: "button_wizard",
        Component: ModalWizzardFolios,
        color: "blue",
        icon: "fi fi-rr-add",
        style: "w-10",
        visible: (name, record) => visibleFolio(name, record, config),
        resetWizard: false,
      },
      transferred_events: {
        type: "button_wizard",
        Component: ModalWizzardEvents,
        color: "yellow",
        icon: "fi fi-rr-add",
        style: "w-10",
        visible: visibleTransfer,
        resetWizard: false,
      },
      add_discount: {
        type: "button_wizard",
        Component: ProductDiscount,
        color: "blue",
        icon: "fi fi-rr-add",
        visible: (name, record) => visibleDiscount(name, record, config),
        resetWizard: false,
      },
      pay_method: {
        type: "button",
        method: "create_sale",
        visible: visiblePay,
        style: "p-22 w-full",
      },
      add_courtesy: {
        type: "button",
        method: "set_courtesy",
        visible: (name, record) => visibleCourtesy(name, record, config),
        style: "p-22 w-full",
      },
      quote: {
        type: "button",
        button_method: "quote",
        visible: visibleQuote,
        style: "p-22 !w-full",
      },
      cancel: {
        type: "button",
        button_method: "cancel",
        visible: true,
        style: "p-22 w-full",
        color: "rose",
      },

      printBrowser: {
        type: "button",
        method: onPrintBrowser,
        visible: visiblePrint,
        // size: "w-32",
        color: "amber",
        iconRight: "fi fi-rr-print",
        loading: false,
      },
    },
    webtree: [
      { name: "number", width: "20%" },
      { name: "party" },
      { name: "description" },
      { name: "party.id_number" },
      { name: "party.mobile", widget: "call-action" },
      { name: "create_uid" },
      { name: "sale_date" },
      { name: "reservation" },
      { name: "courtesy" },
      { name: "paid_amount" },
      { name: "state", widget: "badge" },
    ],
    webform: [
      { name: "party", widget: "search_add_new", edit: false },
      {
        id: "customer",
        grid: [{ name: "sale_date" }, { name: "number" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "consumer_party",
        grid: [
          { name: "description" },
          { name: "agent" },
          { name: "shop" },
          { name: "sale_device" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      { name: "lines", component: "modal" },
      {
        id: "discounts",
        grid: [
          { name: "state", width: "7%" },
          { name: "reservation" },
          { name: "courtesy" },
        ],
        size: [1, 3],
        span: "col-span-1",
      },
      {
        id: "paid",
        grid: [
          { name: "total_amount" },
          { name: "paid_amount" },
          { name: "residual_amount" },
        ],
        size: [1, 3],
        span: "col-span-1",
      },
      {
        id: "actions",
        grid: [
          { name: "quote" },
          { name: "add_payment" },
          // { name: "add_discount" },
          { name: "folios" },
          { name: "transferred_events" },
          { name: "add_courtesy" },
          // { name: "cancel" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      {
        id: "print",
        grid: [{ name: "printBrowser" }],
        size: [1, 4],
        span: "col-span-2",
      },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
