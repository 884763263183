import React, { useState, useEffect } from "react";
import { FormattedMessage as FM } from "react-intl";

// import funcs from "components/Tools/functions";
import { classNames, colors } from "tools/ui";
import upStore from "store/upStore";

const style =
  "z-10 px-4 pt-4 pb-2 bg-white shadow-md text-3xl cursor-pointer border overflow-x-hidden max-w-full rounded-md h-16 z-10 text-gray-500 font-semibold w-full text-right overflow-hidden";

const formatter = Intl.NumberFormat("en-US");

function CharInlineField(props) {
  const { field, attrs, data, autoFocus = false } = props;
  const {
    name,
    readOnly,
    placeholder,
    translate,
    uppercase,
    msg,
    label,
    type,
  } = field;
  const { level, ctxView } = attrs;

  let _value = data.value || "";

  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (autoFocus) {
      focusInput();
    }
  }, [autoFocus]);

  if (_value && ctxView && translate) {
    const _valueRef = `${ctxView.model}.${_value}`;
    _value = intl.msgs[_valueRef] || _value;
  }

  if (field.readOnly) {
    _color = colors.readOnly;
  }

  let _style = focused ? "border-blue-600" : "border-gray-300";

  if (field.class) {
    _style = field.class;
  }

  // function setUpStore(newValue) {
  //   // Código para actualizar el store si es necesario
  // }

  function setUpStore(_value) {
    upStore(level, {
      fieldName: name,
      value: _value,
      field: field,
    });
  }

  function onChange(event) {
    let newValue = event.target.value;
    if (uppercase) {
      newValue = newValue.toUpperCase();
    }
    if (field.specialCharacters && field.type === "char") {
      newValue = newValue.replace(/[^\w\s]|[\dñ]/gi, "");
      newValue = newValue.replace("  ", " ").toUpperCase();
    }
    data.value = type == "number" ? Number(newValue) : newValue;
    if (props.onChange) {
      setUpStore(newValue);
      props.onChange(name, newValue);
    }
  }

  function handleEvent(e) {
    if (e.key === "Delete") {
      onChange({ target: { value: "" } });
    }
  }

  function onBlur() {
    setFocused(false);
    setUpStore(_value);
    if (props.onChange) {
      props.onChange(name, _value);
    }
  }

  function focusInput() {
    setFocused(true);
    const inputElement = document.getElementById(name);
    if (inputElement) {
      inputElement.focus();
      const length = inputElement.value.length;
      inputElement.setSelectionRange(length, length);
    }
  }

  let valueFmt = _value;
  if (type === "number") {
    console.log("test....", valueFmt);
    valueFmt = formatter.format(_value);
  }

  return (
    <div className="flex items-center justify-center space-x-5 w-full mt-4 mb-1 min-h-12 max-w-2xl mx-auto">
      <div className="text-2xl font-bold text-zinc-500 w-3/6 text-right">
        <FM id={label} key={name} />
      </div>
      <div className="w-full overflow-x-hidden">
        <div className={classNames(style, _style)} onClick={focusInput}>
          {valueFmt}
        </div>
        <input
          key={name}
          id={name}
          name={name}
          type="text"
          value={_value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={handleEvent}
          disabled={readOnly ?? false}
          placeholder={placeholder ?? ""}
          title={msg ?? ""}
          className="absolute opacity-0 -z-10"
        />
      </div>
      <span>{field.icon || null}</span>
    </div>
  );
}

export default CharInlineField;
