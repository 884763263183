import QRCode from "qrcode";
// import funcs from "tools/functions";
// import store from "store";
const styleSeparator =
  "border-top: 1px dashed; margin-top:0.5cm; margin-bottom:0.5cm";
const generateInvoiceHTML = async (
  _sale,
  ctxPrinting,
  sale,
  record,
  htmlTax,
  infoTransferRoom,
  infoTransfer,
  imageLogo,
) => {
  const INVOICE_TYPE = {
    P: "SISTEMA POS:",
    1: "FACTURA DE VENTA ELECTRONICA:",
    M: "CORTESIA:",
  };

  const logoDefault =
    "https://res.cloudinary.com/dl4sdiukx/image/upload/v1733183597/swyxqgd45ftno6qvlzui.png";

  const title =
    record.state !== "transferred"
      ? INVOICE_TYPE[sale.invoice_type]
      : "TRANSFERIDO";
  const number = _sale?.number ?? _sale?.order;
  const typeSystem = `<div>${title} <b style="display: block;">${number}</b></div>`;

  let activity = [];
  if (record.state !== "transferred" && record.invoice_type !== "M") {
    let origins = record["lines."]
      .map((item) => (item.origin ? item.origin.id : null))
      .filter((id) => id !== null);

    if (origins.length > 0) {
      const { data } = await proxy.browse("sale_activity.activity", origins, [
        "id",
        "time_start",
        "time_end",
      ]);
      activity = data;
    }
  }

  const contenidoHTML = `
    <div style="width: 62mm; padding: 0.1cm; font-family: monospace; font-size: 2.8mm">
      <div style="text-align: center; margin-bottom: 0.5cm" >
        <div style="margin-bottom: 0.5cm"><center><img src=${imageLogo || logoDefault} width="60%"> </center></div>
        <div>${ctxPrinting.company}</div>
        <div>${ctxPrinting.shop}</div>
        <div>NIT: ${ctxPrinting.id_number} ${ctxPrinting.regime_tax}</div>
        <div>${ctxPrinting.street}</div>
        <div>${ctxPrinting.city} Telefono: ${ctxPrinting.phone}</div>
      </div>
      ${typeSystem}
      <div>Fecha: ${new Date(_sale.sale_date).toLocaleDateString("es-CO")}</div>
      <div style="${styleSeparator}"></div>
      <div>
        <div>Cliente: ${_sale.party}</div>
        ${infoTransferRoom ?? ""}
        ${_sale.description ? `<div>Nombre: ${_sale?.description}</div>` : ""}
        <div>ID: ${_sale.party_id_number}</div>
        <div>Direccion: ${_sale?.party_address || ""}</div>
        <div>Telefono: ${_sale.party_phone}</div>
        <div>Forma de Pago: ${_sale.payment_term}</div>
      </div>
      ${await generateInvoiceLinesHTML(_sale, activity, sale, ctxPrinting)} <!-- Pasamos 'activity' a la función de líneas -->
      <div
        style="${styleSeparator}; border-top:1px dashed ; border-bottom:1px dashed; margin-top: 0.5cm;
        margin-bottom: 0.5cm;"
      ></div>
      ${htmlTax || ""}
      ${infoTransfer || ""}
      <div style=" text-align:center">Creado: ${_sale.create_date} </div>
      <div style=" text-align:center">PROVEEDOR DEL SOFTWARE: PRESIK SAS </div>
      <div style="text-align:center">NIT: 900803782-2</div>
      <div style=" text-align:center">SOFTWARE TRYTON - www.presik.com</div>
    </div>
  `;

  return contenidoHTML;
};

function getQrCode(url) {
  return QRCode.toString(url, { type: "svg" });
}
const generateInvoiceLinesHTML = async (_sale, activity, sale, ctxPrinting) => {
  let imageQr = "";
  if (sale && sale.qr_code) {
    imageQr = await getQrCode(sale?.qr_code, 30, 30);
  }

  const taxes = sale.taxes;
  let taxesHtml = `<div style="display: flex; justify-content: space-between;">
        <div>Tipo</div>
        <div>Base</div>
        <div>Imp.</div>
      </div>`;
  Object.values(taxes).forEach((item) => {
    const { name, base, tax } = item;

    taxesHtml += `
      <div style="display: flex; justify-content: space-between;">
        <div>${name}</div>
        <div>${parseInt(base).toLocaleString("es-CO")}</div>
        <div>${parseInt(tax).toLocaleString("es-CO")}</div>
      </div>
    `;
  });
  return `
  <div style="margin-top: 0.3cm; margin-bottom: 0.1cm; display: flex; justify-content: space-between; border-top: 1px dashed; border-bottom: 1px dashed;">
    <div style="width: 60%;"><b>Articulo</b></div>
    <div style="width: 10%; text-align: center;"><b>Cant</b></div>
    <div style="width: 30%; text-align: right;"><b>Subtotal</b></div>
  </div>
  ${_sale.lines
    .map((item) => {
      let matchingActivity = activity.find((act) => act.id === item.origin?.id);
      let timeStart = matchingActivity
        ? matchingActivity.time_start.slice(0, -3)
        : "";

      return `
          <div style="display: flex; justify-content: space-between; margin-bottom: 0.1cm;">
            <div style="width: 60%;">${item?.name} ${
              item?.discount ? "DESC - " + item?.discount * 100 + "%" : ""
            } ${timeStart ? `(${timeStart})` : ""}</div>
            <div style="width: 10%; text-align: center;">${item?.quantity}</div>
            <div style="width: 30%; text-align: right;">${Math.round(item?.amount_w_tax).toLocaleString("es-CO")}</div>
          </div>
        `;
    })
    .join("")}

  <div style="display: flex; justify-content: space-between; margin-top: 0.3cm;">
    <div><b>Subtotal Base:</b></div>
    <div style="text-align: right;">${Math.round(_sale.untaxed_amount).toLocaleString("es-CO")}</div>
  </div>
  <div style="display: flex; justify-content: space-between;">
    <div><b>Impuestos:</b></div>
    <div style="text-align: right;">${Math.round(_sale.tax_amount).toLocaleString("es-CO")}</div>
  </div>
   <div style="display: flex; justify-content: space-between;  ">
    <div><b>Total:</b></div>
    <div style="text-align: right;">
    <div style="border-top: 1px dashed; width: 20mm; margin-bottom: 0.1cm; "></div>
    ${Math.round(_sale.paid_amount) || Math.round(_sale.total_amount)}
    </div>
    </div>

    <!-- Detalle de impuestos y medio de pago (comentado previamente) -->
    <div style="margin-top: 0.3cm; border-top: 1px dashed; border-bottom: 1px dashed; ;">
        <div><b>DETALLES DE IMPUESTOS</b></div>
        <div style="display: flex; flex-direction: column; gap: 0.2cm;">
            ${taxesHtml}
        </div>
      </div>
      <div style="margin-top: 0.3cm; border-top: 1px dashed; border-bottom: 1px dashed; ;">
        <div><b>MEDIOS DE PAGO</b></div>
        <div style="display: flex; flex-direction: column; gap: 0.2cm;">
          ${_sale.payments
            .map(
              (item) => `
                <div style="display: flex; justify-content: space-between;">
                  <div>${item.name}</div>
                  <div>${item?.voucher || ""}</div>
                  <div>${Math.round(item.amount).toLocaleString("es-CO")}</div>
                </div>
              `,
            )
            .join("")}
        </div>
      </div>

          <div style="display: flex; justify-content: space-between; margin-top: 0.3cm">
            <div>No ITEMS:</div>
            <div>${_sale.lines.length}</div>
          </div>
          <div style="display: flex; justify-content: space-between">
            <div>No PEDIDO:</div>
            <div>${_sale.order}</div>
          </div>
          <div style="display: flex; justify-content: space-between">
            <div>CAJA No.:</div>
            <div>${ctxPrinting.sale_device}</div>
          </div>
          <div style="display: flex; justify-content: space-between">
          <div>VENDEDOR:</div>
          <div>${sale?.agent}</div>
        </div>
<div
        style="${styleSeparator}; border-top:1px dashed ; border-botton:1px dashed; margin-top: 0.3cm;
        margin-bottom: 0.3cm;"
          ></div>
    ${
      _sale.courtesy
        ? `
      <div style="display: flex; justify-content: space-between;">
        <div><b>Total Complimentary:</b></div>
        <div style="text-align: right;">${_sale.total_discount_amount}</div>
      </div>
    `
        : ""
    }

    <div style="margin-top: 0.3cm;">Autorizacion de facturacion ${
      ctxPrinting.authorizations[sale.invoice_type].kind
    } No ${ctxPrinting.authorizations[sale.invoice_type].number} del ${
      ctxPrinting.authorizations[sale.invoice_type].start_date_auth
    } hasta ${
      ctxPrinting.authorizations[sale.invoice_type].end_date_auth
    }, habilita desde ${ctxPrinting.authorizations[sale.invoice_type].prefix}${
      ctxPrinting.authorizations[sale.invoice_type].from_auth
    } a ${ctxPrinting.authorizations[sale.invoice_type].prefix}${
      ctxPrinting.authorizations[sale.invoice_type].to_auth
    }</div>

    <div style= "max-width: 140px; margin: 0px 50;">${imageQr}</div>
    <div style = "width:100%;  word-wrap: break-word; margin-top: 0.2cm;">CUFE:${
      sale.invoice_type == "1" ? _sale.cufe : ""
    }</div>
  `;
};

export { generateInvoiceHTML };

// old

// const onPrintBrowser = async (record) => {
//   const model = "sale.sale";
//   const fieldsActi = ["id", "time_start", "time_end"];
//   const session = store.get("ctxSession");
//   const terminal = funcs.getCookie();
//   const args = { user_id: session.user, device_id: Number(terminal.device_id) };
//   const INVOICE_TYPE = {
//     P: "SISTEMA POS:",
//     1: "FACTURA DE VENTA ELECTRONICA:",
//     M: "CORTESIA:",
//   };
//   const { data: ctxPrinting } = await proxy.methodCall({
//     model: model,
//     method: "get_printing_context",
//     args: [args],
//   });

//   const argSale = {
//     type_doc: "invoice",
//     sale_id: record.id,
//   };
//   const { data: sale } = await proxy.methodCall({
//     model: model,
//     method: "get_data",
//     args: [argSale],
//   });
//   const styleSeparator =
//     "border-top: 1px dashed; margin-top:0.5cm; margin-bottom:0.5cm";

//   let infoTransfer = "";
//   let infoTransferRoom = "";
//   let _sale = { ...record, ...sale };
//   _sale.order = sale?.order;
//   _sale.payment_term = sale?.payment_term;
//   _sale.create_date = new Date(record.sale_date);
//   _sale.create_date = _sale.create_date.toLocaleDateString();
//   _sale.party_address = record.party.address;
//   if (_sale.courtesy) {
//     _sale.total_discount_amount = _sale["lines."].reduce((total, item) => {
//       return total + item.base_price * item.quantity;
//     }, 0);
//   }
//   let imageQr = "";
//   if (sale && sale.qr_code) {
//     imageQr = await getQrCode(sale?.qr_code, 30, 30);
//   }

//   if (record.state !== "transferred" && record.invoice_type != "M") {
//     let origins = record["lines."]
//       .map((item) => (item.origin ? item.origin.id : null))
//       .filter((id) => id !== null);

//     if (origins.length > 0) {
//       const { data: activity } = await proxy.browse(
//         "sale_activity.activity",
//         origins,
//         fieldsActi,
//       );

//       _sale.party = record.party.rec_name;
//       _sale.party_id_number = record.party.id_number;
//       _sale.party_phone = record.party.mobile;

//       _sale.untaxed_amount = _sale.total_amount;
//       if (activity.length > 0) {
//         const _saleLine = _sale["lines."].map((act) => {
//           let matchingActivity = activity?.find((a) => a.id === act.origin.id);
//           let timeStart = matchingActivity?.time_start;
//           if (timeStart) {
//             timeStart = timeStart.slice(0, -3);
//           } else {
//             timeStart = "";
//           }
//           const dataInvoice = {
//             name: ${timeStart} ${name},
//             origin: act.origin,
//             unit_price_w_tax: act.amount,
//             amount_w_tax: act.product.amount_w_tax,
//             quantity: act.quantity,
//             taxes: act.taxes,
//             discount: act.discount,
//           };
//           return dataInvoice;
//         });

//         _sale.lines = _saleLine;
//       }
//     }
//   } else {
//     infoTransfer =
//       "<div style='text-align:center; margin-top: 1.5cm; border-top: 1px dashed;margin-bottom: 0.5cm; padding-top: 0.1cm;'>Firma</div>";

//     infoTransferRoom = <div>${_sale.description}</div>;
//     _sale.party = record.party.rec_name;
//     _sale.party_id_number = record["party."].id_number;
//     _sale.party_address = record["party."].address;
//     _sale.party_phone = record["party."].mobile;
//     _sale.payment_term =
//       _sale.state == "transferred" ? "Transferido" : "Cortesia";

//     let origins2 = record["lines."].map((item) => item.origin);
//     let ids = origins2.map((item) => item.id);
//     const { data: activity2 } = await proxy.browse(
//       "sale_activity.activity",
//       ids,
//       fieldsActi,
//     );
//     let _saleLine = _sale["lines."].map((act) => {
//       let matchingActivity = activity2.find((a) => a.id === act.origin.id);

//       let timeStart = matchingActivity ? matchingActivity?.time_start : "";
//       timeStart = timeStart.slice(0, -3);
//       let name = act.product.name;
//       return {
//         name: ${timeStart} ${name},
//         origin: act.origin,
//         unit_price_w_tax: act.amount,
//         amount_w_tax: act.amount,
//         quantity: act.quantity,
//         taxes: act.product.taxes,
//         discount: act.discount,
//       };
//     });

//     _sale.lines = _saleLine;
//   }
//   let html =
//     <div style="margin-top: 0.5cm; margin-bottom: 0.5cm; display: flex; justify-content: space-between; border-top: 1px dashed; border-bottom: 1px dashed; padding: 2mm;">
//       <div>Articulo</div>
//       <div>Subtotal</div>
//     </div>
//     ${_sale.lines
//       .map(
//         (item) =>
//       <div style="display: flex; justify-content: space-between;margin-bottom: 0.1cm;">
//         <div style="width:70%">${item?.name}  ${
//           item?.quantity != 1 ? "x " + item?.quantity : ""
//         } ${item?.discount ? "DESC - " + item?.discount : ""}</div>
//         <div style="width:30% text-align:rigth">${item?.unit_price_w_tax}</div>
//       </div>
//     ,
//       )
//       .join("")}
//     <div style="display: flex; justify-content: space-between;">
//       <div>Impuestos:</div>
//       <div style="text-align: right;">0</div>
//     </div>
//     <div style="display: flex; justify-content: space-between; margin-top: 0.5cm; ">
//     <div>Total:</div>
//     <div style="text-align: right;">
//     <div style="border-top: 1px dashed; width: 20mm; margin-bottom: 0.1cm; "></div>
//     ${_sale.paid_amount || _sale.total_amount}
//     </div>
//     </div>
//     <div style="display: flex; justify-content: space-between;">
//     ${
//       _sale.courtesy &&
//       <div>Total Complimentary:</div>
//       <div style="text-align: right;">
//       ${_sale.total_discount_amount}
//       </div>
//     }
//     </div>
//     <div style="margin-top: 0.3cm; text-align: center"><b>Por favor llegar al punto de encuentro 15
//     minutos antes de la actividad</b></div>
//   ;

//   let htmlTax;
//   if (record.state != "transferred" && record.invoice_type != "M") {
//     htmlTax = <div>DETALLE DE IMPUESTOS</div>
//   <div
//     style="${styleSeparator}; border-top:1px dashed ; border-botton:1px dashed; margin-top: 0.5cm;
//   margin-bottom: 0.5cm;"
//   ></div>
//   <div style="display: flex; gap: 0.2cm; flex-direction: column">
//     <div style="display: flex; justify-content: space-between">
//       <div>MEDIO PAGO</div>
//       <div># APROB</div>
//       <div>VALOR</div>
//     </div>
//     ${sale.payments
//       .map(
//         (item) =>
//         <div style="display: flex; justify-content: space-between">
//         <div>${item.name}</div>
//         <div>${item?.voucher || ""} </div>
//         <div>${item.amount} </div>
//       </div>
//     ,
//       )
//       .join("")}

//   </div>
//   <div
//     style="${styleSeparator}; border-top:1px dashed ; border-botton:1px dashed; margin-top: 0.5cm;
// margin-bottom: 0.5cm;"
//   ></div>
//   <div style="display: flex; justify-content: space-between">
//     <div>No ITEMS:</div>
//     <div>${_sale.lines.length}</div>
//   </div>
//   <div style="display: flex; justify-content: space-between">
//     <div>No PEDIDO:</div>
//     <div>${_sale.order}</div>
//   </div>
//   <div style="display: flex; justify-content: space-between">
//     <div>CAJA No.:</div>
//     <div>${ctxPrinting.sale_device}</div>
//   </div>
//   <div style="display: flex; justify-content: space-between">
//     <div>CAJERO:</div>
//     <div>${ctxPrinting.user}</div>
//   </div>
//   <div style="display: flex; justify-content: space-between">
//   <div>VENDEDOR:</div>
//   <div>${sale?.agent}</div>
// </div>
//   <div
//   style="${styleSeparator}; border-top:1px dashed ; border-botton:1px dashed; margin-top: 0.5cm;
// margin-bottom: 0.5cm;"
// ></div>
// <div>Autorizacion de facturacion ${
//       ctxPrinting.authorizations[sale.invoice_type].kind
//     } No ${ctxPrinting.authorizations[sale.invoice_type].number} del ${
//       ctxPrinting.authorizations[sale.invoice_type].start_date_auth
//     } hasta ${
//       ctxPrinting.authorizations[sale.invoice_type].end_date_auth
//     }, habilita desde ${ctxPrinting.authorizations[sale.invoice_type].prefix}${
//       ctxPrinting.authorizations[sale.invoice_type].to_auth
//     } a ${ctxPrinting.authorizations[sale.invoice_type].prefix}${
//       ctxPrinting.authorizations[sale.invoice_type].from_auth
//     }</div>
// <div style = "width:100%;  word-wrap: break-word; margin-top: 0.2cm;">CUFE:${
//       sale.invoice_type == "1" ? sale.cufe : ""
//     }</div>
// <div style= "max-width: 140px; margin: 0px 50;">${imageQr}</div>;
//   }
//   const title =
//     record.state !== "transferred"
//       ? INVOICE_TYPE[record.invoice_type]
//       : "TRANSFERIDO";
//   console.log(sale, "this is sale for get number");
//   const number = _sale?.number ?? _sale?.order;
//   const typeSystem =  <div>${title} ${number}</div>;

//   const contenidoHTML =     <div style="width: 62mm; padding: 0.1cm; font-family: monospace; font-size: 2.8mm">
//   <div style="text-align: center; margin-bottom: 0.5cm" >
//     <div style="margin-bottom: 0.5cm"><center><img src=${funcs.base64ToBlobUrl(ctxPrinting?.logo)} width="60%" > </center></div>
//     <div>${ctxPrinting.company}</div>
//     <div>${ctxPrinting.shop}</div>
//     <div>NIT: ${ctxPrinting.id_number} ${ctxPrinting.regime_tax}</div>
//     <div>${ctxPrinting.street}</div>
//     <div>${ctxPrinting.city} Telefono: ${ctxPrinting.phone}</div>
//   </div>
//   ${typeSystem}
//   <div>Fecha: ${_sale.create_date}</div>
//   <div style="${styleSeparator}"></div>
//   <div>
//     <div>Cliente: ${_sale.party}</div>
//     ${infoTransferRoom ?? ""}
//     ${_sale.description ? <div>Nombre: ${_sale?.description}</div> : ""}
//     <div>ID: ${_sale.party_id_number}</div>
//     <div>Direccion: ${_sale?.party_address || ""}</div>
//     <div>Telefono: ${_sale.party_phone}</div>
//     <div>Forma de Pago: ${_sale.payment_term}</div>
//   </div>
//   ${html}
//   <div
//     style="${styleSeparator}; border-top:1px dashed ; border-botton:1px dashed; margin-top: 0.5cm;
//     margin-bottom: 0.5cm;"
//   ></div>
//   ${htmlTax || ""}
//   ${infoTransfer || ""}
//   <div>Fecha de creación: ${_sale.create_date} </div>
//   <div style="margin-top: 0.5cm; text-align:center">NIT: 900803782-2</div>
//   <div style=" text-align:center">SOFTWARE TRYTON - www.presik.com</div>
// </div>
// <div>
// </div>
// ;

//   const ventana = window.open("", "", "width=500,height=600");
//   ventana.document.open();
//   ventana.document.write(contenidoHTML);
//   ventana.document.close();
//   ventana.print();
// };
