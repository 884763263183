import useMainStore from "store/mainStore";
import ProductDiscountItem from "../components/ProductDiscountLines";

const searchUnit = (value, record) => {
  let dom = [["category", "=", record?.unit?.category]];
  if (value) {
    dom.push(["name", "ilike", `%${value}%`]);
  }
  return dom;
};

const getAmountDiscount = (record) => {
  // let activeRecord = useMainStore.getState().record;

  // if (activeRecord.state === "draft") {
  // let lines = activeRecord.lines;
  //   for (let line of lines.values()) {
  //     if (line.product.components && line.product.components.size > 0) {
  //       const unit_price_w_tax = Array.from(
  //         line.product.components.values(),
  //       ).reduce(
  //         (total, item) =>
  //           total + item.product.sale_price_w_tax * item.quantity,
  //         // total + item.product.sale_price_w_tax * item.quantity,
  //         0,
  //       );
  //       const list_price = Array.from(line.product.components.values()).reduce(
  //         (total, item) => total + item.product.list_price * item.quantity,
  //         0,
  //       );

  //       line.unit_price_w_tax = unit_price_w_tax;
  //       line.unit_price = list_price;
  //       line.base_price = list_price;
  //     }
  //   }
  // }

  if (record?.quantity && record?.discount_amount) {
    return record.discount_amount;
  }
};

const getAmountWTax = (record) => {
  if (record?.quantity && record?.unit_price_w_tax) {
    return record.quantity * record.unit_price_w_tax;
  }
};

const getDiscount = (record) => {
  if (record?.discount_rate) {
    return (record.discount_rate * 100).toFixed(2).toString() + " %";
  }
};

const accDelete = (rec) => {
  let res = true;
  if (rec && rec.state !== "draft") {
    res = false;
  }
  return res;
};

const readOnlyQuantity = (record) => {
  const parentRec = useMainStore.getState().record;
  if (parentRec.state !== "draft") {
    return true;
  }
  return false;
};

const onChangeQty = async (record, store) => {
  const parentRec = useMainStore.getState().record;

  if (parentRec.lines.size > 0) {
    const totalAmount = Array.from(parentRec.lines.values()).reduce(
      (total, item) => {
        let sale_price = item.unit_price_w_tax ?? item.product.sale_price_w_tax;
        return total + item.quantity * sale_price;
      },
      0,
    );
    parentRec.total_amount = Number(totalAmount.toFixed(0));
  }
};

const visibleDiscount = () => {
  const activeRecord = useMainStore.getState().record;
  return activeRecord?.state !== "draft" ? false : true;
};

const getView = () => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    form_action_add: "modal",
    model: "sale.line",
    table_action: ["delete"],
    form_action: ["add", "delete"],
    access: {
      delete: accDelete,
      edit: accDelete,
    },
    otherActions: [],
    selectable: null,
    webfields: {
      product: {
        type: "many2one",
        model: "product.product",
        attrs: ["id", "list_price", "sale_price_w_tax", "name"],
        required: true,
      },
      "product.code": {
        type: "char",
        readOnly: true,
      },
      unit: {
        type: "many2one",
        model: "product.uom",
        recSearch: searchUnit,
      },
      quantity: {
        type: "integer",
        required: true,
        editable: true,
        default: 1,
        withChange: onChangeQty,
        readOnly: readOnlyQuantity,
        min: 1,
      },
      base_price: {
        type: "numeric",
      },
      sale_price_taxed: {
        type: "numeric",
      },
      discount_amount: {
        type: "numeric",
        readOnly: true,
        decimalPlaces: 0,
        function: getAmountDiscount,
        search: true,
      },
      discount_rate: {
        type: "char",
        readOnly: true,
        function: getDiscount,
        search: true,
      },
      status_order: {
        type: "char",
        readOnly: true,
        default: "draft",
        translate: true,
      },
      unit_price_w_tax: { type: "numeric" },
      amount: {
        type: "numeric",
        readOnly: true,
      },
      amount_w_tax: { type: "numeric", function: getAmountWTax },
      "origin.time_start": { type: "char" },
      "origin.time_end": { type: "char" },
      origin: { type: "many2one", readOnly: true },
      add_discount: {
        type: "button_custom_modal",
        readOnly: true,
        color: "sky",
        Component: ProductDiscountItem,
        visible: visibleDiscount,
      },
    },
    webtree: [
      { name: "product", width: "40%" },
      { name: "quantity", width: "10%", widget: "increment" },
      { name: "base_price", width: "23%" },
      { name: "discount_rate", width: "23%" },
      { name: "discount_amount", width: "23%" },
      // { name: "unit_price", width: "23%" },
      { name: "unit_price_w_tax", width: "23%" },
      { name: "amount_w_tax", width: "23%" },
      { name: "add_discount", width: "40%" },
    ],
    webform: [],
  };
  return DictCtxView;
};

export default { ctxView: getView };
