import React, { useEffect } from "react";
import { signal } from "@preact/signals-react";

import { CheckCircleIcon } from "@heroicons/react/20/solid";
import StdButton from "components/Buttons/StdButton";
import proxy from "api/proxy";
import { FormattedMessage as FM } from "react-intl";
import { useWizardStore } from "store/wizardStore";

const isLoading = signal(false);

const Invoice = ({ data, colors }) => {
  const { store } = useWizardStore();
  const redirectPayment = async () => {
    isLoading.value = true;
    const saleArgs = {
      model: "electronic.payment",
      method: `${store?.methodPayment?.process_method}_charge`,
      args: [
        {
          journal: store?.methodPayment.id,
          amount: data?.total.toString(),
          iva: 0,
          description: data?.rec_name,
          party: data?.id_party,
          customer_name: data?.name,
          customer_last_name: data?.lastname || "Sin apellido",
          customer_phone: data?.mobile,
          customer_email: data?.email,
          origin: `hotel.booking,${data?.id}`,
        },
      ],
    };
    const { data: result, error } = await proxy.methodCall(saleArgs);

    if (result?.type === "redirect_url") {
      window.location.replace(result.url);
      isLoading.value = false;
    } else {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      store?.methodPayment?.id &&
      store?.methodPayment?.process_method !== "manual"
    ) {
      isLoading.value = true;
      const timer = setTimeout(() => {
        redirectPayment();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div
      className={`${colors?.bgColor} w-full flex  flex-col justify-center items-center h-[500px] rounded-md text-white space-y-3`}
    >
      <CheckCircleIcon className="w-20" />
      <h2 className={`${colors.textColorPrimary} font-semibold text-xl`}>
        {data.rec_name}
      </h2>
      <span className="uppercase">
        <span className={colors.textColorPrimary}>¡</span>
        <FM id="app.booking.create_reserve" />{" "}
        <span className={colors.textColorPrimary}>
          <FM id="app.booking.successfully" />!
        </span>
      </span>
      <span className="text-xs text-gray-400 pb-4">
        <FM id="app.booking.validate_email" />
      </span>

      {isLoading.value && (
        <span>
          <FM id="app.booking.redirect" /> ...{" "}
        </span>
      )}
    </div>
  );
};

export default Invoice;
