import React, { Fragment, useEffect } from "react";
import FormWizard from "components/MeForm/FormWizard";
import { useWizardStore } from "store/wizardStore";
import mainStore from "store/mainStore";
import proxy from "api/proxy";
import dates from "tools/dates";
import ModelWizardPayment from "./models/ModelWizardPayment";
import ModelSale from "./models/ModelSale";
import func from "../../tools/functions";
import { useBtnLoading } from "store/btnLoading";
import { set } from "store";

const WizardAddPayment = ({ onClose }) => {
  const {
    store: storeWizard,
    record: recordWizard,
    reset,
    updateWizard,
  } = useWizardStore();

  const { setRecord, record, store, updateRecord } = mainStore();

  const { setButtonLoading } = useBtnLoading();
  const ctxViewSale = ModelSale.ctxView();

  useEffect(() => {
    let _residual = record.total_amount - record.paid_amount;
    const rec = { amount: _residual };
    updateWizard(rec);
    // upActiveWizard(rec);
  }, []);

  const handleAddPayment = async () => {
    const _storeRecord = { ...store };
    const _data = func.recToTryton(_storeRecord);
    if (!record.party) {
      onClose(false, "missing_party");
      return;
    }
    const requireVoucher =
      recordWizard?.statement?.["journal."]?.require_voucher;
    if (
      !storeWizard.statement ||
      (requireVoucher && !storeWizard.voucher) ||
      !storeWizard.amount
    ) {
      setButtonLoading(false);
      console.error("Voucher is required");
      return;
    }
    let recordId = record.id;

    await proxy.saveQuery({
      model: "sale.sale",
      storeRec: _data,
    });

    const toStore = {
      statement: storeWizard.statement,
      amount: String(storeWizard.amount),
      number: storeWizard.voucher,
      date: dates.dateToday(),
      description: record.number,
      party: record.party.id,
      account: record.party.account_receivable,
      source: `sale.sale,${recordId}`,
      sale: recordId,
    };
    const { data, error } = await createStatementLine(toStore);
    if (data) {
      const sale = data[0]?.["sale."];
      const residual_amount = sale?.residual_amount;

      if (residual_amount > 0) {
        updateRecord({ ...record, ...sale });
        updateWizard({
          amount: residual_amount,
          statement: null,
          voucher: null,
        });
      } else {
        const _storeRecord = { ...store };
        _storeRecord.sale_id = recordId;
        const saleDoneError = await callSaleDoneMethod(recordId);
        onClose(true, saleDoneError);
        setRecord(recordId, "sale.sale", ctxViewSale);
        reset();
      }
      setButtonLoading(false);
    }

    return true;
  };

  const createStatementLine = async (toStore) => {
    const model = "account.statement.line";
    const fields_names = ["id", "sale.residual_amount", "sale.paid_amount"];
    return await proxy.create(model, toStore, fields_names);
  };

  const callSaleDoneMethod = async (recordId) => {
    const sale = { sale_id: recordId };
    const argSaleDone = {
      model: "sale.sale",
      method: "faster_process",
      args: [sale],
    };
    const { data } = await proxy.methodCall(argSaleDone);
    const argSalePost = {
      model: "sale.sale",
      method: "faster_post_invoice",
      args: [sale],
    };

    const { dataPost } = await proxy.methodCall(argSalePost);
    const argSaleReconcile = {
      model: "sale.sale",
      method: "reconcile_invoice",
      args: [sale],
    };

    const { dataReconcile, errorReconcile } =
      await proxy.methodCall(argSaleReconcile);
    console.log(errorReconcile);
    return dataReconcile;
  };

  const ctxView = ModelWizardPayment.ctxView();

  return (
    <Fragment>
      <FormWizard
        key="hotel-booking-wizard-payment"
        ctxView={ctxView}
        parentRec={record}
        handleButton={handleAddPayment}
      />
    </Fragment>
  );
};

export default WizardAddPayment;
