import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { FormattedMessage as FM } from "react-intl";

import StdButton from "components/Buttons/StdButton";
import { classNames } from "tools/ui";

let panel =
  "bg-stone-50 transform rounded-lg text-left shadow-xl transition-all overflow-visible";

let child = "flex justify-center px-4 text-center sm:p-0 blu";

/**
 *
 * @param {object} props - component props
 * @param {Function} props.onClose - function for close modal
 * @param {Function} props.onAccept - function for accept modal
 * @param {Boolean} props.open - true or false
 * @param {String} props.title - string title to show
 * @param {width} props.width - define width modal
 * @param {height} props.height - define with height
 * @param {Node} props.children - childs node for this component
 * @returns {Component}
 */

let width = "w-full sm:w-10/12 sm:my-8 sm:p-6";

const BasicModal = (props) => {
  if (props.width) {
    width = props.width;
  }

  let height = "h-min";

  if (props.height) {
    height = props.height;
  }
  const title = "!my-auto font-bold leading-6 text-white mb-2";
  let titleSize = "text-3xl";
  if (props.titleSize) {
    titleSize = props.titleSize;
  }

  const buttonsList = {
    cancel: (
      <StdButton
        key="cancel"
        color="rose"
        size="w-full sm:w-1/2 md:w-1/3"
        onClick={props.onClose}
        content="basic_modal.button_cancel"
      />
    ),
    ok: (
      <StdButton
        key="ok"
        color="blue"
        size="w-full sm:w-1/2 md:w-1/3"
        onClick={props.onAccept}
        content="basic_modal.button_ok"
      />
    ),
    close: (
      <StdButton
        key="close"
        color="black"
        size="w-full sm:w-1/2 md:w-1/3"
        onClick={props.onClose}
        content="basic_modal.button_close"
      />
    ),
  };

  const groupButtons = props.buttons?.map((btn) => {
    if (buttonsList[btn]) {
      return buttonsList[btn];
    } else {
      return btn;
    }
  });

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={props.onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-opacity-50 transition-opacity bg-gray-600 z-auto" />
        </Transition.Child>

        <div id="basic-modal" className="fixed inset-0  mt-12 h-full z-auto ">
          <div className={classNames(child)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={classNames(panel, width, height)}>
                {props.title && (
                  <div className="-z-auto flex p-4 mb-4 ml-auto my-auto bg-blue-presik text-white rounded-t-md">
                    <Dialog.Title className={classNames(title, titleSize)}>
                      <FM id={props.title} />
                    </Dialog.Title>
                    <XMarkIcon
                      onClick={props.onClose}
                      className="ml-auto h-7 w-7 text-white stroke-2 p-1 cursor-pointer hover:text-rose-700"
                      aria-hidden="true"
                    />
                  </div>
                )}
                <div
                  id="basic-modal-children"
                  className="relative px-4 pb-2 ml-auto my-auto overflow-y-auto max-h-[78vh] overflow-x-hidden"
                >
                  {props.children}
                </div>
                {props.buttons && props.buttons.length > 0 && (
                  <div className="justify-end px-4 pb-4 mt-2 sm:mt-4 flex flex-col md:flex-row gap-4 ">
                    {groupButtons}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default BasicModal;
