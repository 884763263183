import React, { Fragment, useState } from "react";
import { FormattedMessage as FM } from "react-intl";

import BasicModal from "components/Modals/BasicModal";
import ModalMsg from "components/Modals/ModalMsg";
import view from "./views";
import funcs from "tools/functions";
import proxy from "api/proxy";
import { useWizardStore } from "store/wizardStore";
import { classNames } from "tools/ui";

const actionStyle =
  "flex p-4 text-gray-500 text-lg mx-auto hover:bg-stone-200 hover:cursor-pointer rounded-md active:scale-95";

const MONEY = [
  100000, 50000, 20000, 10000, 5000, 2000, 1000, 500, 200, 100, 50,
];

const rows = new Map();

for (const value of MONEY) {
  rows.set(value, {
    id: value,
    bill: value,
    quantity: 0,
  });
}

function FormWizard({ name, icon, ctxView }) {
  const terminal = funcs.getCookie();
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState(null);
  const [step, setStep] = useState("start");
  const [buttons, setButtons] = useState(["cancel", "ok"]);
  const { record, store, updateWizard, setTrigger, reset } = useWizardStore();

  let currentView;
  if (name === "open") {
    currentView = view.openView;
  } else {
    currentView = view.closeView;
  }
  const { steps, buttonName } = currentView;

  async function onAccept() {
    // setLoading(true);
    const model = "account.statement";
    const dom = [["code", "=", terminal.device_code]];
    const fields = ["id", "name"];
    const { store } = useWizardStore.getState();
    let money = [];
    if (store.money) {
      for (const rec of store.money.values()) {
        money.push([rec.id, rec.quantity]);
      }
    }
    const { data: devices } = await proxy.search("sale.device", dom, fields);
    let device;
    let _step;
    if (devices.length > 0) {
      device = devices[0];
      let argsData = {
        device: device.id,
      };

      let method;
      if (name === "open") {
        method = "do_open_statement";
        argsData.start_balance = store.start_balance;
      } else {
        method = "do_close_statement";
        argsData.money = money;
        argsData.data = money;
      }
      const args = [argsData];
      const { data, error } = await proxy.methodCall({ model, method, args });
      if (error) {
        _step = "error";
        setMsg(error.error);
      } else {
        _step = "success";
      }
    } else {
      _step = "error";
    }
    setButtons(["close"]);
    setStep(_step);
  }

  function onCloseMsg() {
    setMsg(null);
    setStep("start");
    setButtons(["cancel", "ok"]);
  }

  function getStep() {
    const { Component, args } = steps[step];
    return Component({ ...args });
  }

  function onCloseModal() {
    setOpen(false);
    setStep("start");
    setButtons(["cancel", "ok"]);
    reset();
  }

  function onChangeRows(_store) {
    let _total = 0;
    for (const value of _store.money.values()) {
      _total += value.bill * value.quantity;
    }
    record.total = _total;
    updateWizard({ ...store, total: funcs.fmtMoney(_total) });
  }

  function openModal() {
    setTrigger(onChangeRows);
    if (name === "close") {
      const record = {
        total: 0,
        money: rows,
        start_balance: 0,
      };
      updateWizard(record);
    }
    if (ctxView?.defaults) {
      // This method set default values for the wizard
      ctxView.defaults();
    }
    setOpen(true);
  }

  return (
    <Fragment>
      <div key={buttonName} className={actionStyle} onClick={openModal}>
        <FM id={buttonName} key={buttonName} />
        <i
          key={icon}
          className={classNames("flex text-xl my-auto ml-6", icon)}
        />
        {msg && (
          <ModalMsg
            open={true}
            type="error"
            msg={msg}
            buttons={["close"]}
            onClose={onCloseMsg}
          />
        )}
      </div>
      <BasicModal
        title={buttonName}
        open={open}
        onClose={onCloseModal}
        buttons={terminal.device_code ? buttons : []}
        onAccept={onAccept}
        titleSize="text-2xl"
        width="w-full sm:w-4/5 md:w-3/4 lg:w-2/3"
      >
        {getStep()}
      </BasicModal>
    </Fragment>
  );
}

export default FormWizard;
