import React, { useState, Fragment } from "react";
import store from "store";

import RestCategories from "./RestCategories";
import RestProducts from "./RestProducts";
import IconButton from "components/Buttons/IconButton";
import { useStoreRest } from "./storeRest";
import PureModal from "components/Modals/PureModal";
import StdButton from "components/Buttons/StdButton";
import { signal } from "@preact/signals-react";
import { XCircleIcon } from "@heroicons/react/20/solid";

const openModal = signal(false);

function MenuRest(props) {
  const sessionUser = store.get("ctxSession");
  const [view, setView] = useState("categories");
  const [message, setMessage] = useState(null);
  const [category, setCategory] = useState(null);
  const { catsIndex, currentCats, gotoCategory, setCategories } =
    useStoreRest();

  setCategories();

  function onChange(action, record) {
    setMessage(null);
    if (action === "back") {
      gotoCategory();
      setView("categories");
    } else {
      setCategory(record);
      gotoCategory(record.id);
      if (record.childs) {
        setView("categories");
      } else {
        setView("products");
      }
    }
  }

  const ContentModal = () => {
    return (
      <div id="modal-menu-rest-order" className="p-4 pt-16">
        <XCircleIcon
          className="absolute w-12 h-12 top-3 right-3 active:scale-95 transition-all duration-200 cursor-pointer"
          onClick={() => {
            openModal.value = false;
          }}
        />
        {catsIndex.length > 0 && (
          <IconButton
            onClick={() => onChange("back")}
            color="stone"
            name="fi fi-rr-arrow-small-left"
            tooltip="board.button_back"
          />
        )}

        {view === "categories" ? (
          <RestCategories onChange={onChange} visibleCats={currentCats} />
        ) : (
          <Fragment>
            <div className="flex">
              {message && (
                <div className="flex text-sky-700 mx-auto my-auto font-bold gap-x-3">
                  {message}
                  <i
                    key="check"
                    className="flex text-lime-500 text-2xl text-bold my-auto fi fi-br-check"
                  />
                </div>
              )}
            </div>
            <RestProducts
              sessionUser={sessionUser}
              category={category}
              record={props.record}
              updateMsg={setMessage}
            />
          </Fragment>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <StdButton
        content="VER MENÚ"
        onClick={() => (openModal.value = true)}
        size="w-full"
        color={"yellow"}
      />
      <PureModal
        open={openModal.value}
        onClose={() => (openModal.value = false)}
      >
        <ContentModal />
      </PureModal>
    </Fragment>
  );
}

export default MenuRest;
