import React, { Fragment } from "react";
import BasicModal from "components/Modals/BasicModal";
import TableActivity from "./TableActivity";

const ModalWizardActi = ({
  label,
  record,
  model,
  ctxView,
  parentRec,
  onOpen,
  onClose,
  view,
}) => {
  function handleClose() {
    onClose(false);
  }

  return (
    <Fragment>
      <BasicModal
        title={label}
        open={onOpen}
        onClose={handleClose}
        buttons={[]}
        titleSize="text-2xl"
        width="w-1/2"
      >
        <TableActivity
          parentRec={parentRec}
          model={model}
          record={record}
          onClose={() => handleClose()}
          ctxView={ctxView}
          view={view}
        />
      </BasicModal>
    </Fragment>
  );
};

export default ModalWizardActi;
