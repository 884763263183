import React from "react";

import Board from "components/Meta/Board";
import party from "./Party";

function PartyScreen() {
  const ctxView = party.ctxView();
  return (
    <div className="flex w-1/2">
      <Board ctxView={ctxView} />
    </div>
  );
}

export default PartyScreen;
