// Spanish translation

const es = {
  "account.statement.start_balance": "Saldo Base",
  "account.statement.line": "Pago / Anticipo",
  "account.statement.line.amount": "Valor",
  "account.statement.line.statement": "Medio de Pago",
  "account.statement.line.date": "Fecha",
  "account.statement.line.number": "Numero",
  "account.statement.line.party": "Tercero",
};

export default es;
