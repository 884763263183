import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import React from "react";

export const SearchRecord = ({
  data,
  setSearchData,
  resetSearchData,
  title = "",
}) => {
  const handleSearchRecord = (value) => {
    let _value = value;
    let filterActivities = [];
    if (_value === "") {
      resetSearchData();
    } else {
      filterActivities = data.filter((activity) => {
        const activityName = activity.name.toLowerCase();
        return activityName.includes(_value.toLowerCase());
      });
    }

    if (filterActivities.length > 0) {
      setSearchData(filterActivities);
    }
  };

  return (
    <div className="mx-auto bg-gray-100 border border-gray-300 shadow-md p-2 max-w-md mb-5 rounded-md flex justify-between">
      <input
        type="text"
        id="searchActi"
        onChange={(e) => handleSearchRecord(e.target.value)}
        placeholder={title}
        className="bg-transparent w-full outline-none px-2 "
      />
      <MagnifyingGlassIcon className="h-full w-5" />
    </div>
  );
};
