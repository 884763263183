import proxy from "api/proxy";
import moves from "./ModelStockMove";
import store from "store";
import tools from "tools/dates";

let today = tools.dateToday();

// const ctxSession = store.get("ctxSession");
function onChangeWarehouse(rec, store) {
  console.log(rec, store);

  console.log(rec, store);
  store.warehouse_input = rec.warehouse["input_location"];
  // store.warehouse_input = store.customer["customer_location."].id;
  // rec.warehouse_storage = rec.customer["customer_location."];
  store.warehouse_storage = rec.warehouse["storage_location"];
}

function onChangeCustomer(rec, store) {
  console.log(rec);
  if (rec.customer.addresses.length > 0) {
    store.contact_address = rec.customer.addresses[0];
  }
  // today =
  // rec.effective_date =
  // store.effective_date =
  // store.planned_date =
}

const defaultWarehouse = async () => {
  const user = ctxSession?.user;
  console.log(ctxSession, "session");

  const { data, error } = await proxy.search(
    "res.user",
    [["id", "=", user]],
    ["id", "warehouse.name"],
  );
  if (data && data.length > 0) {
    const warehouse = data[0]["warehouse."];
    await store.set({ ...ctxSession, locations: [warehouse.id] });
    return warehouse;
  }
};

const getView = () => {
  let DictCtxView = {
    model: "stock.shipment.out.return",
    selectable: true,
    activeSearch: true,
    limit: 50,
    // domain: [],
    form_action: ["save", "edit", "add"],
    table_action: ["open", "add"],
    webfields: {
      customer: {
        type: "many2one",
        model: "party.party",
        required: true,
        attrs: ["addresses", "customer_location"],
        withChange: onChangeCustomer,
        // readOnly: true,
        searchable: true,
      },

      number: { type: "char", readOnly: true },
      reference: { type: "char", readOnly: false },
      effective_date: {
        type: "date",
        default: today,
        // readOnly: true,
      },
      planned_date: {
        type: "date",
        default: today,
        readOnly: true,
      },
      state: {
        type: "char",
        readOnly: true,
        translate: true,
      },
      invoice_state: {
        type: "char",
        readOnly: true,
        translate: true,
      },
      warehouse: {
        type: "many2one",
        model: "stock.location",
        attrs: ["input_location", "storage_location"],
        withChange: onChangeWarehouse,
        // default: defaultWarehouse,
        // readOnly: true,
      },
      // warehouse_input: {
      //   type: "many2one",
      //   model: "stock.location",
      //   readOnly: true,
      // },
      moves: {
        type: "one2many",
        model: "stock.move",
        ctxView: moves,
        required: true,
        readOnly: { state: ["quotation"] },
      },
      receive: {
        type: "button",
        button_method: "receive",
        // visible: getVisible,
        onSuccessMsg: "ORDEN DE COMPRA CONFIRMADA!",
        color: "pink",
      },
    },
    webform: [
      {
        id: "infoShipment",
        grid: [
          { name: "number" },
          { name: "effective_date" },
          { name: "planned_date" },
          { name: "state" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      {
        id: "infoCustomer",
        grid: [
          { name: "customer" },
          { name: "warehouse" },
          { name: "reference" },
          // { name: "warehouse_input", width: "20%" },
        ],
        size: [1, 3],
        span: "col-span-2",
      },

      { name: "moves" },
      { name: "receive" },
    ],
    webtree: [
      { name: "number", width: "20%" },
      { name: "customer", width: "20%" },
      { name: "reference", width: "20%" },
      // { name: "party", width: "20%" },
      { name: "effective_date", width: "20%" },
      { name: "planned_date", width: "20%" },
      { name: "state", width: "20%" },
      { name: "warehouse", width: "20%" },
      // { name: "warehouse_input", width: "20%" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
