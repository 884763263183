import React, { Fragment, useState } from "react";

import BasicModal from "components/Modals/BasicModal";
import { BUTTON_COLORS } from "components/Constants/constants";
import { classNames } from "tools/ui";
import { FormattedMessage as FM } from "react-intl";

const style =
  "block mx-auto w-full h-10 text-center border-[1px] justify-center rounded-xl active:scale-95 active:shadow-sm";

function TButtonCustomModal({ field, attrs, data }) {
  const { name, icon, color, desired_action, Component } = field;
  const { readOnly, record, level, model, isVisible } = attrs;

  const [open, setOpen] = useState(false);

  let value = `${model}.${name}`;

  if (desired_action === "sum") {
    value = data.value.reduceRight(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    );
  } else if (desired_action === "count") {
    value = data.value.length;
  } else if (desired_action === "value") {
    value = data.value;
  }
  const _color = color ?? "blue";
  const [bgColor, txtColor, border] = BUTTON_COLORS[_color];

  function onClose() {
    setOpen(false);
  }

  return (
    <Fragment>
      {isVisible && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
          className={classNames(style, bgColor, txtColor, border)}
          aria-label={name}
        >
          {field.labeled !== false && (
            <span
              className={classNames(
                "w-42 ml-auto  my-auto px-2 active:scale-95",
              )}
            >
              {desired_action ? value : <FM id={value} />}
            </span>
          )}
          {icon && (
            <i
              className={classNames("mr-auto my-auto text-xl", icon, txtColor)}
            />
          )}
        </button>
      )}
      <BasicModal
        title={model + "." + name}
        open={open}
        onClose={onClose}
        titleSize="text-2xl"
        width="w-1/2"
      >
        <Component record={record} onClose={() => onClose()} name={name} />
      </BasicModal>
    </Fragment>
  );
}

export default TButtonCustomModal;
