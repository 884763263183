import React from "react";

import { classNames } from "tools/ui";

const style = "px-3 py-0 grid gap-x-4 lg:px-3 lg:py-3";

function FormGrid(props) {
  let cols = "md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2";
  if (props.cols) {
    cols = props.cols;
  }
  return (
    <div id="form-grid" className="bg-slate-50">
      <div className={classNames(style, cols)}>{props.children}</div>
    </div>
  );
}

export default FormGrid;
